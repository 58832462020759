import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SeatDataModel, SectionDataModel } from '../shared/models';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { InventoryService } from './inventory.service';

@Injectable({
  providedIn: 'root'
})
export class FlowControlService {
  public availabilityBlockmap: { [key: string]: SectionDataModel } = {};
  public availabilitySeatmap: { [key: string]: SeatDataModel } = {};
  public sectionSelected: string;
  public planSelected: number;
  public flowStep: 'blockmap' | 'seatmap';
  private sectionData$ = new Subject<SectionDataModel>();
  private seatData$ = new Subject<SeatDataModel>();
  public isGa = false;
  public seatHash;
  public viewerMode: 'customer' | 'inventory';
  public colorsmode: 'inventory' | 'pricing';
  public seatClickId: string;
  // Pricing storage
  public seatpricing = {};
  public areapricing = {};

  public seatAll: SeatDataModel = null; // seat that holds if exists the section-all-seats seat

  constructor(private dvmService: DvmService,
              private inventoryService: InventoryService) {
  }


  // tslint:disable-next-line:variable-name
  setSectionData(_sectionData: SectionDataModel) {
    this.sectionData$.next(_sectionData);
  }

  getSectionData(): Observable<SectionDataModel> {
    return this.sectionData$.asObservable();
  }

  // tslint:disable-next-line:variable-name
  setSeatData(_seatData: SeatDataModel): void {
    this.seatData$.next(_seatData);
  }

  getSeatData(): Observable<any> {
    return this.seatData$.asObservable();
  }

  showMiniMap(): void {
    document.getElementById('minimap').classList.remove('d-none');
    document.getElementById('minimap').classList.add('d-md-block');
  }

  hideMiniMap(): void {
    document.getElementById('minimap').classList.remove('d-md-block');
    document.getElementById('minimap').classList.add('d-none');
  }

  renewHash(): void {
    const currentSelection = this.dvmService.viewer.getNodesByState('seat', 'selected');
    for (const seat of currentSelection) {
      if (!this.seatHash[seat.id]) {
        this.seatHash[seat.id] = true;
      }
    }
    this.inventoryService.seatsSelectedLengh.next(this.dvmService.viewer.getNodesByState('seat', 'selected').length);
  }

  // iterate through availability and get a seat with row all if exists
  public setSeatAll(availability: SeatDataModel[]) {
    this.seatAll = null;
    console.log('setSeatAll Func Availability ->', availability);
    for (const seat of availability) {
      if (seat.seat_row === 'all' && seat.price_type !== null) {
        console.log('yes');
        this.seatAll = seat;
      }
    }
  }

}
