import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SalesRepListComponent } from './sales-rep-list/sales-rep-list.component';
import { AuthGuard } from '../auth';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailsComponent } from './user-details';
import { UserCommonComponent } from './user-common/user-common.component';


const routes: Routes = [

  {
    path: '',
    component: UserCommonComponent,
    children: [
      {
        path: 'customers',
        component: UserListComponent
      },
      {
        path: 'customers/:id',
        component: UserDetailsComponent
      },
      {
        path: 'staff',
        component: SalesRepListComponent
      }
    ]
  },
];

// {
//   path: ':id',
//   component: UserDetailsComponent
// },
// {
//   path: 'sales-rep',
//   component: SalesRepListComponent,
//   // canActivate: [AuthGuard],
// },
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
