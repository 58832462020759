import { Component, OnInit } from '@angular/core';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { LoadHandler } from '../handlers/load.handler';
import { ClickHandler } from '../handlers/click.handler';
import { PopoverCreateHandler } from '../handlers/popover-create.handler';
import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { SelectionAreaHandler } from '../handlers/selection-area.handler';
import { FlowControlService } from '../services/flow-control.service';

@Component({
  selector: 'app-pricing-landing',
  templateUrl: './pricing-landing.component.html',
  styleUrls: ['./pricing-landing.component.scss'],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    SelectionAreaHandler
  ]
})
export class PricingLandingComponent implements OnInit {

  constructor(private dvmService: DvmService,
              private clickHandler: ClickHandler,
              private loadHandler: LoadHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private selectionAreaHandler: SelectionAreaHandler,
              private flowControl: FlowControlService) { }

  ngOnInit() {
    this.dvmService.restartDVM();
  }

  get step(): string {
    return this.flowControl.flowStep;
  }

  get legendsMode(): string {
    return this.flowControl.colorsmode;
  }

}
