import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../services';
import { TransactionModel } from '../../transaction-management/shared';

@Component({
  selector: 'app-user-transaction',
  templateUrl: './user-transaction.component.html',
  styleUrls: ['./user-transaction.component.scss']
})
export class UserTransactionComponent implements OnInit {

  @Input() userId;
  transactionList: Array<TransactionModel>;
  // Pagination variables
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  constructor(private userService: UserService) {
    this.currentPage = 1;
    this.itemsPerPage = 10; // Same number in API Settings.
  }

  ngOnInit() {
    this.init();

  }

  private init(): void {
    this.userService.getUserTransactions(this.currentPage, this.userId).subscribe(
      (response) => {
        this.totalItems = response.count;
        this.transactionList = response.results;
      }
    );
  }

  statusTranslate(status: string): string {
    let instance = 'none';
    switch (status) {
      case 'PH' :
        instance = 'On Hold';
        break;
      case 'OK' :
        instance = 'OK';
        break;
      case 'PR' :
        instance = 'Reserved';
        break;
      case 'CA' :
        instance = 'Cancelled';
        break;
    }

    return instance;
  }

  isRefund(price: number): boolean {
    let refund;
    (price >= 1) ? refund = false : refund = true;
    return  refund;
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', {maximumFractionDigits: 2});
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.userService.getUserTransactions(this.currentPage, this.userId).subscribe(
      data => {
        this.transactionList = data.results;
      });
  }
}
