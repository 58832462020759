import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-guide-component',
  templateUrl: './guide.component.html'
})
export class GuideComponent implements OnInit {

  bsModalRef: BsModalRef;

  constructor() { }

  ngOnInit() {
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'smooth'});
  }

  onDownload(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/data/STAPLES_BackofficeGuide.pdf');
    link.setAttribute('download', `Backoffice_Support_Guide.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
