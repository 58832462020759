import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AddApiUrlInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const finalReq = req.url.startsWith('http') || req.url.includes('/assets/') ? req :
      req.clone({ url: `${environment.apiUrl}${req.url}`});
    return next.handle(finalReq);
  }
}
