import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ModalData } from '../info-modal/modal-data';
import { ModalComponent } from '../info-modal/modal.component';

@Injectable()
export class AuthModalService {

    bsModalRef: BsModalRef;
    ifShowed = true;

    constructor(private modalService: BsModalService) { }

    private prepareObject(object) {
        for (const i in object) {
            if (object[i] === undefined) {
                delete object[i];
            }
        }
    }

    public authModal(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
        const config: ModalOptions = {
            animated: true,
            keyboard: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered',
            initialState: {
                title,
                content: message,
                closeBtnName,
                closeFunction,
                acceptBtnName,
                acceptFunction

            }
        };
        modalParentClass = modalParentClass ? modalParentClass : '';
        modalParentClass += ' error-modal';
        this.prepareObject(config.initialState);
        this.bsModalRef = this.modalService.show(ModalComponent, config);
    }

    public changeFlag(): void {
        this.ifShowed = false;
    }

}
