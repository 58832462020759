import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { AuthGuard } from '../auth';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { TransactionCommonComponent } from './transaction-common/transaction-common.component';


const routes: Routes = [
  {
    path: '',
    component: TransactionCommonComponent,
    children: [
      {
        path: 'list',
        component: TransactionListComponent
      },
      {
        path: 'list/:id',
        component: TransactionDetailComponent
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionManagementRoutingModule { }
