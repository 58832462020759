import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  config: any = {
    // progressBar: true,
    // progressAnimation: 'decreasing',
    positionClass: 'toast-bottom-center'
  };

  constructor(private toastr: ToastrService) { }

  showSuccess(message, title?, config?) {
    this.toastr.success(message, title, this.config);
  }
  showInfo(message, title?, config?) {
    this.toastr.info(message, title, this.config);
  }
  showWarning(message, title?, config?) {
    this.toastr.warning(message, title, this.config);
  }
  showError(message, title?, config?) {
    this.toastr.error(message, title, this.config);
  }
}
