import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UserService } from '../services';
import { UserModel } from '../shared';
import { NgForm } from '@angular/forms';
import { UserRegisterModel } from '../shared/models/user-register.model';
import { Observable } from 'rxjs';
import { SalesRepModel } from '../shared/models';
import { RegisterService } from '../services/register.service';
import { ValidateFormService } from 'src/app/services/validate-form.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DjangoSessionAuthenticationService } from '../../auth';
import { BackofficeUser } from '../../shared/models';


@Component({
  selector: 'app-user-modal-details',
  templateUrl: './user-form-modal.component.html'
})
export class UserFormModalComponent implements OnInit {

  user: UserModel | UserRegisterModel;
  userLogged: BackofficeUser;
  defaultUser: UserModel | UserRegisterModel;
  bsModalRefNested: BsModalRef;
  process: 'register' | 'update';
  repList: Observable<Array<SalesRepModel>>;

  constructor(public bsModalRef: BsModalRef,
              private userService: UserService,
              private auth: DjangoSessionAuthenticationService,
              private registerService: RegisterService,
              public formValidatorService: ValidateFormService,
              private notificationService: NotificationService) {
    this.process = 'register';
    this.defaultUser = { is_active: true, type: 'customer' } as UserRegisterModel;
    // this.user = {} as UserRegisterModel;
  }

  ngOnInit() {
    if (this.user) {
      this.defaultUser = JSON.parse(JSON.stringify(this.user));
      this.process = 'update';
    }
    this.auth.getUserLogged$().subscribe((data) => {
      if (typeof data !== 'boolean') {
        this.userLogged = data;
      }
    });
    this.init();
  }

  private init(): void {
    this.repList = this.userService.getSalesRep();
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.createUser(form);
  }

  private createUser(form: NgForm): void {
    this.registerService.postCustomerRegistration(this.defaultUser).subscribe(
      response => {
        this.notificationService.showSuccess('Customer created');
        this.user = response;
        this.bsModalRef.hide();
      },
      error => {
        this.notificationService.showError('Could not create customer');
        console.log(error);
      }
    );
  }



}
