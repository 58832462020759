import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private dateFactory: DatePipe;

  constructor() {
    this.dateFactory = new DatePipe('en');
  }

  transformFormDate(date: string, time: string): string {
    return this.dateFactory.transform(date + 'T' + time, 'yyyy-MM-ddTHH:mm:SSZ');
  }

  getTimestamp(date: string, time: string): number {
    const timestamp = new Date(date + 'T' + time).getTime();
    return timestamp;
  }
}
