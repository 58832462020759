import { Injectable } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidateFormService {

  constructor() { }

  hasGeneralErrors(form: NgForm, control: NgModel): boolean {
    return form.submitted && form.invalid && control.invalid;

  }

  hasIndividualError(form: NgForm, control: NgModel, error: string): boolean {
    return form.submitted && control.errors && control.errors[error];
  }
}
