import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DvmStylesService } from './dvm-styles.service';

@Injectable({
    providedIn: 'root'
})
export class DvmService {

    viewer: D2M.Viewer | null = null;
    viewer3d: DVM.Viewer3d | null = null;
    viewerSubject: Subject<D2M.Viewer> = new Subject();
    isViewerSubjectInitialized = false;
    // Básicamente lo que hace Partial<T> es coger un objeto T y convertir todas sus propiedades en opcionales,
    // así no te ves forzado a crear todos los callbacks
    subscribedCallbacks = {};

    constructor(@Inject(DVM_CONFIG) private dvmConfig,
                private dvmStyleService: DvmStylesService) {
        // this.initializeMap(dvmConfig);
        this.viewerSubject.subscribe({
            next: (viewerSubject: D2M.Viewer) => this.viewer = viewerSubject
        });
    }

    public initializeMap(config: D2M.IViewerInitializerOptions): void {
        DVM.loadModule('map_viewer', config)
            .then((viewer) => {
                this.viewer = viewer;
                this.viewer.flags.automatic_selection = false;
                (window as any).viewer = viewer;
                this.loadMap(this.dvmConfig);
                if (!this.isViewerSubjectInitialized) {
                    this.viewerSubject.next(this.viewer);
                    this.isViewerSubjectInitialized = true;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    public restartDVM(): void {
        // tslint:disable-next-line: forin
        for (const event in this.subscribedCallbacks) {
            this.subscribedCallbacks[event].forEach(callback => {
                console.log(event);
                this.viewer.unsubscribe(event as any, callback);
            });
        }
        this.isViewerSubjectInitialized = false;
        this.initializeMap(this.dvmConfig);
    }

    // private initialize3dView(config: DVMConfiguration): void {
    //     DVM.loadModule('3d_viewer', config)
    //         .then((viewer3d) => {
    //             this.viewer3d as = viewer3d;
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    public loadMap(loadOptions: DVMConfiguration): Promise<void> {
        return this.viewer.loadMap(loadOptions)
            .then(() => {
                this.applyStyles(this.viewer);
            });
    }

    public load3DView(nodeId): void {
        const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
        loadOptions.view_id = nodeId;
        loadOptions.venue_id = this.viewer.getVenueId();
        this.viewer3d.loadView3d(loadOptions);
    }

    public changeMapConfiguration(venueId): void {
        if (this.viewer) {
            const viewerConfig = JSON.parse(JSON.stringify(this.dvmConfig));
            viewerConfig.venue_id = venueId;
            viewerConfig.map_id = this.viewer.getMapId();
            this.loadMap(viewerConfig);
        }
    }


    // tslint:disable-next-line: max-line-length
    //   public subscribeHandler<T extends keyof Pick<D2M.IViewerPublicTriggers, 'click' | 'end_load' | 'enter' | 'leave'>>(event: T, callback: D2M.IViewerPublicTriggers[T]): void {
    //     const viewerSubjectSubscribe = this.viewerSubject.subscribe(
    //         viewer => {
    //             this.viewer.subscribe(event, callback);
    //             if (!this.subscribedCallbacks[event]) {
    //                 this.subscribedCallbacks[event] = [];
    //             }
    //             this.subscribedCallbacks[event].push(callback);
    //             viewerSubjectSubscribe.unsubscribe();

    //         }
    //     );
    //     }

    public subscribeHandler(event: 'click' | 'end_load' | 'enter' | 'leave' | 'update_selection_area', callback) {
        // Para el selection plugin hay 2 callbacks mas
        // start_selection_area y end_selection_area
        const viewerSubjectSubscribe = this.viewerSubject.subscribe(
            viewer => {
                this.viewer.subscribe(event, callback);
                if (!this.subscribedCallbacks[event]) {
                    this.subscribedCallbacks[event] = [];
                }
                this.subscribedCallbacks[event].push(callback);
                viewerSubjectSubscribe.unsubscribe();
            }
        );
    }

    private applyStyles(viewer: D2M.Viewer) {
        const styles =
            [
                // Level 0. sections are visible, seats are hidden (no styles needed).
                {
                    // Level 0 styles for nodes with "section" type
                    section: {
                        available: {
                            normal: {
                                none: {
                                    fillStyle: '#32CD32',
                                    strokeStyle: 'white',
                                    fillOpacity: 0.7,
                                    lineWidth: 1.5,
                                    opacity: 1,
                                    cursor: 'auto'
                                },
                            },
                            hover: {
                                none: {
                                    fillStyle: '#A2A2A2',
                                    lineWidth: 2,
                                    cursor: 'pointer'
                                }
                            }
                        },
                        unavailable: {
                            normal: {
                                none: {
                                    fillStyle: 'transparent',
                                    strokeStyle: 'transparent',
                                    cursor: 'auto'
                                }
                            }
                        },
                        selected: {
                            normal: {
                                none: {
                                    fillStyle: 'white',
                                    strokeStyle: 'white',
                                    fillOpacity: 0.7,
                                    opacity: 1,
                                    lineWidth: 2,
                                    cursor: 'pointer'
                                },
                            },
                        },
                        disabled: 'unavailable'
                    },
                    // Level 0 styles for nodes with "seat" type (no styles = not shown)
                    seat: {
                        available: {
                            normal: {
                                none: {
                                    fillStyle: '#32CD32',
                                    strokeStyle: 'white',
                                    fillOpacity: 0.7,
                                    lineWidth: 0.1,
                                    cursor: 'auto'
                                },
                                owned: {
                                  fillStyle: 'red',
                                  strokeStyle: 'white',
                                },
                                on_hold: {
                                  fillStyle: 'yellow',
                                },
                                reserved: {
                                  fillStyle: 'white',
                                },
                                nopricetype: {
                                  fillStyle: 'pink',
                                },
                                available_internal: {
                                  fillStyle: 'mediumpurple',
                                },
                                internal_hold: {
                                  fillStyle: 'orange',
                                },
                                locked: {
                                  icon: 'icon-cross',
                                  iconScale: 0.7,
                                  iconFillStyle: 'black',
                                },
                            },
                            hover: {
                                none: {
                                    fillStyle: '#A2A2A2',
                                    lineWidth: 0.2,
                                    cursor: 'pointer'
                                },
                                owned: {
                                  fillStyle: '#A2A2A2',
                                },
                                on_hold: {
                                  fillStyle: '#A2A2A2',
                                },
                                reserved: {
                                  fillStyle: '#A2A2A2',
                                },
                                nopricetype: {
                                  fillStyle: '#A2A2A2',
                                },
                                available_internal: {
                                  fillStyle: '#A2A2A2',
                                },
                                internal_hold: {
                                  fillStyle: '#A2A2A2',
                                },
                            }
                        },
                        selected: {
                            normal: {
                                none: {
                                    fillStyle: '#A2A2A2',
                                    strokeStyle: 'white',
                                    fillOpacity: 0.7,
                                    lineWidth: 0.2,
                                }
                            },
                            hover: {
                                none: {
                                  cursor: 'pointer'
                                }
                            }
                        },
                        unavailable: {
                            normal: {
                                none: {
                                    fillStyle: 'transparent',
                                    strokeStyle: 'transparent',
                                    cursor: 'auto'
                                }
                            }
                        },
                        disabled: 'unavailable'
                    }
                }
            ];
        viewer.setStyles(styles);
    }

    private getNodeId(obj): void {
        return (obj.nodes.length > 0) ? obj.nodes[0].id : null;
    }

    public setStyles(nodes: Array<any>): object {
        return this.dvmStyleService.setSeatHash(nodes);
    }


}
