import { Component, Inject, OnInit } from '@angular/core';
import { SeatDataModel, SectionDataModel } from '../shared';
import { BackofficeUser, MessagingService, PlanModel } from 'src/app/shared';
import { FlowControlService, InventoryService } from '../services';
import { NotificationService } from 'src/app/services/notification.service';
import { DvmService } from '../../shared/services/dvm.service';
import { saveAs } from 'file-saver';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { AssignCustomerModalComponent, AssignData } from '../assign-customer-modal/assign-customer-modal.component';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG, BackofficeConfigurationModel } from '../../configuration';
import { DjangoSessionAuthenticationService } from '../../auth';
import { PlanSeatCommentModel } from '../../pricing-management/shared';

@Component({
  selector: 'app-side-interface',
  templateUrl: './side-interface.component.html',
  styleUrls: ['./side-interface.component.scss']
})
export class SideInterfaceComponent implements OnInit {

  constructor(
    // @Inject(APP_CONFIG) private appConfig: BoConfiguration,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    public flowservice: FlowControlService,
    private msgService: MessagingService,
    private modalService: BsModalService,
    private authService: DjangoSessionAuthenticationService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private dvmService: DvmService) {
    this.planSelected = 1;
    this.flowservice.planSelected = this.planSelected;
    this.flowservice.viewerMode = 'inventory';
    this.flowservice.colorsmode = 'inventory';
  }

  get getSeatSelected(): number {
    return this.seatSelectedLenght;
  }

  user: BackofficeUser;
  planSelected: number;
  sectionData: SectionDataModel;
  seatData: SeatDataModel;
  planList: Array<PlanModel>;
  seatStatus: string;
  seatSelectedLenght: number;
  errorOnSave = false;
  action: 'lock' | 'unlock';
  seatStatusChange: 'available' | 'internal_hold' | 'available_internal';
  bsModalRef: BsModalRef;

  // Select Map
  selectedMap = 0;
  selectableMaps: object;
  selectableMapsArray: Array<object>;

  static isEmpty(str) {
    return (!str || str.length === 0);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.planList = data.plansInfo.plans;
      this.planSelected = data.plansInfo.fullPlanId;
    });
    this.authService.getUserLogged().then((user) => {
      if (typeof user !== 'boolean') {
        this.user = user;
      }
    });
    // set plan if exit query param
    this.flowservice.planSelected = this.planSelected;
    this.selectableMaps = {
      'nam-us-00036-lakers-premium': { id: 0, name: 'Lakers', venue: 'nam-us-00036-lakers-premium' },
      'nam-us-00036-kings-premium': { id: 2, name: 'Kings', venue: 'nam-us-00036-kings-premium' },
      'nam-us-00036-concert-premium': { id: 3, name: 'Concert', venue: 'nam-us-00036-concert-premium' }
    };
    this.selectableMapsArray = Object.values(this.selectableMaps);
    this.flowservice.getSectionData().subscribe(
      data => {
        this.sectionData = data;
      }
    );
    this.flowservice.getSeatData().subscribe(
      data => {
        this.seatData = data;
        if (this.seatData && this.flowservice.viewerMode === 'inventory') {
          this.seatStatus = this.getSeatStatus();
        }
      }
    );
    this.inventoryService.getSeatSelectedLength().subscribe(
      length => {
        this.seatSelectedLenght = length;
      }
    );
    this.inventoryService.errorOnSaveSubject.subscribe(
      error => {
        this.errorOnSave = error;
      }
    );

  }

  public getSeatStatus(): string {
    if (this.seatData.final_event_status.length === 0) {
      // if (this.seatData.locked === true) {
      //   return 'locked';
      // }
      return 'available';
    } else {
      switch (this.seatData.final_event_status[0].status) {
        case 'on_hold': {
          return 'on_hold';
        }
        case 'owned': {
          return 'sold';
        }
        case 'reserved': {
          return 'reserved';
        }
        case 'sold': {
          return 'sold';
        }
        case 'internal_hold': {
          return 'internal_hold';
        }
        case 'available_internal': {
          return 'available_internal';
        }
      }
    }
  }

  // Carga la disponibilidad total del estadio con los datos de los respectivos dueños
  onChangePlanSelectorInventory(newPlan?: number): void {
    this.flowservice.seatAll = null; // put null for not show message section sold entirely while loading
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.viewerMode = 'inventory';
    this.flowservice.colorsmode = 'inventory';
    const step = this.flowservice.flowStep;
    this.flowservice.planSelected = newPlan;
    if (step === 'blockmap') {
      this.inventoryService.getPlanInventory(newPlan).subscribe(
        availabilityBlockmap => {
          for (const s of Object.keys(availabilityBlockmap)) {
            if (this.appConfig.suitesHash.hasOwnProperty(`${s}(INT)`)) {
              availabilityBlockmap[`${s}(INT)`] = availabilityBlockmap[s];
              delete availabilityBlockmap[s];
            } else if (this.appConfig.suitesHash.hasOwnProperty(`${s}(INT)R`)) {
              availabilityBlockmap[`${s}(INT)R`] = availabilityBlockmap[s];
              delete availabilityBlockmap[s];
            }
          }
          this.dvmService.viewer.setAvailability('section', []);
          this.dvmService.viewer.setAvailable('section', Object.keys(availabilityBlockmap));
          this.flowservice.availabilityBlockmap = availabilityBlockmap;
        },
        error => {
          this.dvmService.viewer.setAvailable('section', []);
        }
      );
    }
    if (step === 'seatmap') {
      const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
      loadOptions.map_id = this.dvmService.viewer.getMapId();
      this.dvmService.loadMap(loadOptions);
      this.dvmService.viewer.setAvailable('seat', []);
      this.inventoryService.getSectionInventory(newPlan, this.dvmService.viewer.getMapId()).subscribe(
        availability => {
          const section = this.dvmService.viewer.getMapId();
          this.flowservice.availabilitySeatmap = this.inventoryService.getSeatmapAvailability(availability);
          const filteredAvailability = this.inventoryService.createAvailabilityHashmap(availability);
          if (!section.includes('PR') && this.flowservice.seatAll) {
            let correctFilter = null;
            for (const key of Object.keys(filteredAvailability)) {
              if (filteredAvailability[key][this.flowservice.seatAll.id]) {
                correctFilter = key;
              }
            }
            for (const item of Object.keys(filteredAvailability.seat4All)) {
              filteredAvailability[correctFilter][item] = filteredAvailability.seat4All[item];
            }
          }
          // tslint:disable-next-line: max-line-length
          this.dvmService.viewer.setAvailable('seat', Object.keys(filteredAvailability.available).concat(Object.keys(filteredAvailability.locked)));
          this.inventoryService.setSeatsGroup(filteredAvailability);
          const seatStyles = this.dvmService.setStyles(this.dvmService.viewer.getNodesByType('seat'));
          // for (const seat of Object.keys(seatStyles)) {
          //   (this.dvmService.viewer as any).setNodeCustomStyles(seat, [seatStyles[seat]]);
          // }
          // iterate through availability and see if there is a seat with row = all
          this.flowservice.setSeatAll(availability);
        },
        error => {
          this.dvmService.viewer.setAvailable('seat', []);
        }
      );
    }
  }

  // Carga la disponibilidad del plan, como la veria un cliente.
  onChangePlanSelectorCustomer(newPlan?: number): void {
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.colorsmode = 'pricing';
    this.flowservice.viewerMode = 'customer';
    const step = this.flowservice.flowStep;
    this.flowservice.planSelected = newPlan;
    if (step === 'blockmap') {
      this.inventoryService.getPlanInventory(newPlan).subscribe(
        availabilityBlockmap => {
          this.dvmService.viewer.setAvailability('section', []);
          const correctAvailability = this.excludeSectionWithoutSeats(availabilityBlockmap);
          this.dvmService.viewer.setAvailable('section', Object.keys(correctAvailability));
          this.flowservice.availabilityBlockmap = availabilityBlockmap;
        },
        error => {
          this.dvmService.viewer.setAvailable('section', []);
        }
      );
    }
    if (step === 'seatmap') {
      const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
      loadOptions.map_id = this.dvmService.viewer.getMapId();
      this.dvmService.loadMap(loadOptions);
      this.inventoryService.removeSeatsGroups();
      this.dvmService.viewer.addNodesToGroup(this.dvmService.viewer.getNodesByGroups('seat', 'unavailable'), 'customerView');
      this.dvmService.viewer.setAvailable('seat', []);
      this.inventoryService.getPlanAvailabilitySection(newPlan, this.dvmService.viewer.getMapId()).subscribe(
        availability => {
          this.flowservice.availabilitySeatmap = availability;
          this.dvmService.viewer.setAvailable('seat', Object.keys(availability));
          const seatStyles = this.dvmService.setStyles(this.dvmService.viewer.getNodesByType('seat'));
          for (const seat of Object.keys(seatStyles)) {
            (this.dvmService.viewer as any).setNodeCustomStyles(seat, [seatStyles[seat]]);
          }
        },
        error => {
          this.dvmService.viewer.setAvailable('seat', []);
        });
    }
  }

  onEnterSection(): void {
    this.flowservice.sectionSelected = this.sectionData.name;
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.map_id = this.sectionData.name;
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.dvmService.loadMap(loadOptions);
  }


  findPlanName(planid: any): string {
    const condition = (p) => p.id === parseInt(planid, 10);
    const i = this.planList.findIndex(condition);
    return this.planList[i].name;
  }

  getseatSection(seatID: string): string {
    return seatID.split('-')[0].split('_')[1];
  }

  getseatRow(seatID: string): string {
    return seatID.split('-')[1];
  }

  getseatSeat(seatID: string): string {
    return seatID.split('-')[2];
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }

  excludeSectionWithoutSeats(availability: any): any {
    const list = {};
    for (const block of Object.keys(availability)) {
      // console.log(availability[block]);
      // tslint:disable-next-line:max-line-length
      if (availability[block].seats !== 0 && !availability[block].name.includes('S_Lexus Club') && !availability[block].name.includes('S_Lot C') && !availability[block].name.includes('S_Lot W')) {
        list[block] = availability[block];
      }
    }
    return list;
  }

  private onSaveLock(seatList: any): void {
    this.inventoryService.lockSeat(seatList, this.planSelected).subscribe(
      data => {
        if (this.flowservice.seatAll !== null) {
          seatList = Object.keys(this.flowservice.availabilitySeatmap);
        }
        this.notificationService.showSuccess('Seat status saved');
        this.dvmService.viewer.removeNodesFromGroup(seatList, 'available');
        this.dvmService.viewer.addNodesToGroup(seatList, 'locked');
        // Añadimos el estado locked al asiento. TODO Formatear objeto correctamente
        // tslint:disable-next-line: forin
        for (const seatId of Object.keys(this.flowservice.seatHash)) {
          const seatObject = this.flowservice.availabilitySeatmap[seatId];
          seatObject.locked = true;
        }

      },
      err => {
        this.notificationService.showError('Could not save status');
        console.log(err);
      }
    );
  }

  private onSaveUnlock(seatList: any): void {
    this.inventoryService.unlockSeats(seatList, this.planSelected).subscribe(
      data => {
        if (this.flowservice.seatAll !== null) {
          seatList = Object.keys(this.flowservice.availabilitySeatmap);
        }
        this.notificationService.showSuccess('Seat status saved');
        this.dvmService.viewer.removeNodesFromGroup(seatList, 'locked');
        this.dvmService.viewer.addNodesToGroup(seatList, 'available');
        // Borramos cualquier rastro del anterior estado
        for (const seatId of Object.keys(this.flowservice.seatHash)) {
          const seatObject = this.flowservice.availabilitySeatmap[seatId];
          seatObject.locked = false;
        }
      },
      err => {
        this.notificationService.showError('Could not save status');
        console.log(err);
      }
    );
  }

  public onExport(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.msgService.info(title, message, acceptBtnName, () => {
      this.exportCSV();
    }, closeBtnName);
  }

  exportCSV(): void {
    this.inventoryService.getPlanCSV(this.planSelected).subscribe(
      data => {
        const planName = this.findPlanName(this.planSelected).replace(/\s/g, '_');
        saveAs(data, `plan_${planName}.csv`);
      }
    );
  }

  public onExportSection(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.msgService.info(title, message, acceptBtnName, () => {
      this.exportSectionCSV();
    }, closeBtnName);
  }

  private exportSectionCSV(): void {
    const section = this.dvmService.viewer.getMapId();
    this.inventoryService.getPlanFromSectionCSV(this.planSelected, section).subscribe(
      data => {
        const planName = this.findPlanName(this.planSelected).replace(/\s/g, '_');
        saveAs(data, `plan_${planName}.csv`);
      }
    );
  }

  // Abre el modal para elegir usuario al que asignar el asiento.
  openAssignModal(): void {
    const assignData: AssignData = { seat: null, plan: null };
    if ( this.flowservice.seatAll ) {
      assignData.seat = [this.flowservice.seatAll];
    } else {
      assignData.seat = this.seatData;
    }
    assignData.plan = this.planSelected;
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        assignData
      }
    };
    this.bsModalRef = this.modalService.show(AssignCustomerModalComponent, modalConfig);

  }

  // onOpenRegisterModal(): void {
  //   const modalConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered',
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //     initialState: {}
  //   };
  //   const initialState = {};
  //   this.bsModalRef = this.modalService.show(UserFormModalComponent, modalConfig);
  //
  // }

  selectChange(newValue) {
    this.flowservice.setSeatData(null);
    this.flowservice.setSectionData(null);
    this.selectedMap = newValue;
    // tslint:disable:no-string-literal
    this.dvmService.changeMapConfiguration(this.selectableMapsArray[newValue]['venue']);
  }

  typeOfSection(): 'standard' | 'table' {
    const sect = this.flowservice.sectionSelected;
    try {
      if (sect.includes('PR')) {
        return 'standard';
      }
    } catch (e) {
    }
    return 'table';
  }

  wichType(): 'suite' | 'table' {
    const sect = this.flowservice.sectionSelected;
    try {
      if (sect.includes('TB')) {
        return 'table';
      }
    } catch (e) {
    }
    return 'suite';
  }

  tableIsNotAssigned(): any {
    const s = this.flowservice.seatAll;
    if (s.final_event_status.length === 0 || s.final_event_status[0].status === 'available_internal'
      || s.final_event_status[0].status === 'internal_hold') {
      return true;
    }
    return false;
  }

  goBack() {
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.flowservice.flowStep = 'blockmap';
    this.flowservice.setSectionData(null);
    this.flowservice.setSeatData(null);
    this.flowservice.seatHash = {};
    this.flowservice.sectionSelected = null;
    this.inventoryService.seatsSelectedLengh.next(Object.keys(this.flowservice.seatHash).length);
    this.dvmService.loadMap(loadOptions);
  }

  isSoldEntirely() {
    return this.flowservice.seatAll !== null;
  }

  getSuitesSMCTotalSeats() {
    return this.flowservice.seatAll.seat;
  }

  removeUnderLine(text: string): string {
    return text.replace('_', ' ');
  }

  // copied on inventory list component as the permissions are the same
  isChangeStatusShown() {
    let show = false;
    if (this.user.type === 'admin') {
      if (this.seatStatus === 'available' || this.seatStatus === 'internal_hold' || this.seatStatus === 'available_internal') {
        show = true;
      }
    }
    return show;
  }

  isChangeCommentShown() {
    return this.user.type === 'admin' || this.user.type === 'management';
  }

  isLockUnlockShown() {
    return this.user.type === 'admin';
  }

  isAssignSeatShown() {
    return !this.flowservice.seatAll && this.seatStatus === 'available' && this.getSeatSelected=== 1;
  }

  isAssignTableOrSuiteShown() {
    return this.flowservice.flowStep === 'seatmap' && this.flowservice.seatAll && this.tableIsNotAssigned()
  }

  // add/change/remove actions
  // Guardar, crear o borrar un comentario.
  onSaveComment(inputRef: HTMLInputElement, seat: SeatDataModel, action: 'save' | 'edit' | 'delete'): void {
    const seatList = this.helperSeatMultiSelect();
    const comment = inputRef.value;
    this.inventoryService.changeSeatComment(seatList, this.planSelected, inputRef.value).subscribe(
      data=> {
        this.notificationService.showSuccess('Seat comment saved');
        for (const seatId of Object.keys(this.flowservice.seatHash)) {
          const seatObject = this.flowservice.availabilitySeatmap[seatId];
          seatObject.comments = comment ? [{comment, plan: this.planSelected, seat: seatId}] : [];
        }
      }
    )
  }
  onLockUnlock(): void {
    const seatList = this.helperSeatMultiSelect();
    if (this.action === 'lock') {
      this.onSaveLock(seatList);
    } else if (this.action === 'unlock') {
      this.onSaveUnlock(seatList);
    }
  }

  onSaveStatusChange(): void {
    let seatList = this.helperSeatMultiSelect();
    const groups = ['available', 'available_internal', 'internal_hold'];
    this.inventoryService.changeSeatStatus(seatList, this.planSelected, this.seatStatusChange).subscribe(
      data => {
        this.notificationService.showSuccess('Seat status saved');
        // borrar los nodos de su grupo
        if (this.flowservice.seatAll !== null) {
          seatList = Object.keys(this.flowservice.availabilitySeatmap);
        }
        for (const g of groups) {
          this.dvmService.viewer.removeNodesFromGroup(seatList, g);
        }
        this.dvmService.viewer.addNodesToGroup(seatList, this.seatStatusChange);
        if (this.seatStatusChange === 'available') {
          for (const seatId of Object.keys(this.flowservice.seatHash)) {
            const seatObject = this.flowservice.availabilitySeatmap[seatId];
            seatObject.final_event_status.length = 0;
            // also take out the locked status https://mmcbcn.atlassian.net/browse/IT-5469
            seatObject.locked = false;
            const seatsToUpdate= this.flowservice.seatAll !== null ? Object.keys(this.flowservice.availabilitySeatmap): seatList;
            this.dvmService.viewer.removeNodesFromGroup(seatsToUpdate, 'locked');
          }
        } else {
          for (const seatId of Object.keys(this.flowservice.seatHash)) {
            const seatObject = this.flowservice.availabilitySeatmap[seatId];
            // seatObject.final_event_status[0].status = this.seatStatusChange;
            if (seatObject.final_event_status[0]) {
              seatObject.final_event_status[0].status = this.seatStatusChange;
            } else {
              seatObject.final_event_status[0] = {
                status: null,
                seat: null,
                plan: this.planList.find(data => data.id === this.planSelected),
                id: null,
                customer_plan: null,
                customer: null
              };
              seatObject.final_event_status[0].status = this.seatStatusChange;
            }
          }
        }
      }
    );
  }

  // helper method to get seat
  private helperSeatMultiSelect(): string[] {
    const seatList = []; // Listado vacio de las sillas para actualizar estado
    // tslint:disable-next-line: forin
    if (this.flowservice.seatAll) {
      seatList.push(this.flowservice.seatAll.id);
    } else {
      for (const seatId of Object.keys(this.flowservice.seatHash)) {
        seatList.push(seatId);
      }
    }
    return seatList;
  }
}
