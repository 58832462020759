import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { SalesRepListComponent } from './sales-rep-list/sales-rep-list.component';
import { FormsModule } from '@angular/forms';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';
import { SalesRepModalDetailsComponent } from './sale-rep-modal-details';
import { UserListComponent } from './user-list/user-list.component';
import { UserFormModalComponent } from './user-form-modal';
import { UserDetailsComponent } from './user-details';
import { CustomerPlanComponent } from './customer-plan';
import { CustomerPlanSeatsComponent } from './customer-plan-seats';
import { CustomerPlanModalComponent } from './customer-plan-modal';
import { CustomerPlanNewSeatModalComponent } from './customer-plan-new-seat-modal/customer-plan-new-seat-modal.component';
import { UserTransactionComponent } from './user-transaction';
import { UserCommonComponent } from './user-common/user-common.component';
import { ReservedSeatsComponent } from './reserved-seats/reserved-seats.component';


@NgModule({
  declarations: [
    SalesRepListComponent,
    SalesRepModalDetailsComponent,
    UserListComponent,
    UserFormModalComponent,
    UserDetailsComponent,
    CustomerPlanComponent,
    CustomerPlanSeatsComponent,
    CustomerPlanModalComponent,
    CustomerPlanNewSeatModalComponent,
    UserTransactionComponent,
    UserCommonComponent,
    ReservedSeatsComponent
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    FormsModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot()
  ],
  entryComponents: [SalesRepModalDetailsComponent, UserFormModalComponent,
    CustomerPlanModalComponent, CustomerPlanNewSeatModalComponent]
})
export class UserManagementModule { }
