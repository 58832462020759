import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapTopbarComponent } from './components/map-topbar/map-topbar.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { MapLegendComponent } from './components/map-legend/map-legend.component';



@NgModule({
  declarations: [
    MapTopbarComponent,
    MapViewComponent,
    MapLegendComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MapTopbarComponent,
    MapViewComponent,
    MapLegendComponent
  ]
})
export class SharedModule { }
