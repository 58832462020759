import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeatEventStatusCustomerModel, UserModel } from '../shared';
import { CustomerPlanModel, PaginatedResponseModel, PriceModel } from '../../shared';
import { formatDate } from '@angular/common';
import { SalesRepModel } from '../shared';
import { TransactionModel } from '../../transaction-management/shared';
import { DateService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private dateFactory: DateService) {
  }

  getUsers(page: number): Observable<PaginatedResponseModel<UserModel>> {
    return this.http.get<PaginatedResponseModel<UserModel>>(`/user_readonly/?page=${page}`);
  }

  getFilteredUsers(filter: any, page: number): Observable<PaginatedResponseModel<UserModel>> {
    return this.http.get<PaginatedResponseModel<UserModel>>(`/user_readonly/?page=${page}`, {params: filter});
  }


  searchCustomer(filter: any, page: number): Observable<Array<UserModel>> {
    return this.http.get<Array<UserModel>>(`/user_nopaginated_readonly/`, {params: filter});
  }

  getUserDetails(id: number): Observable<UserModel> {
    return this.http.get<UserModel>(`/user_readonly/${id}/`);
  }

  getCustomerPlanSeatsCSV(params: any): any {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
      params
    };
    return this.http.get(`/customerplanseats_export_csv/`, HTTPOptions);
  }

  getCustomerPlansCSV(params: any): any {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
      params
    };
    return this.http.get(`/customerplan_export_csv/`, HTTPOptions);
  }

  getCustomerPlan(id: number): Observable<Array<CustomerPlanModel>> {
    return this.http.get<Array<CustomerPlanModel>>(`/filtered_customerplan/?customer=${id}`).pipe(
      map((data: Array<CustomerPlanModel>) => {
        data.forEach((cplan) => {
          cplan.appointment_date_from_date = formatDate(cplan.appointment_date_from, 'yyyy-MM-dd', 'en');
          cplan.appointment_time_from_date = formatDate(cplan.appointment_date_from, 'HH:mm', 'en');
          cplan.appointment_date_to_date = formatDate(cplan.appointment_date_to, 'yyyy-MM-dd', 'en');
          cplan.appointment_time_to_date = formatDate(cplan.appointment_date_to, 'HH:mm', 'en');
        });
        return data;
      })
    );
  }

  patchUserDetails(target: UserModel): Observable<UserModel> {
    return this.http.patch<UserModel>(`/user_manage/${target.id}/`, target);
  }

  takeOverRequest(payload: { customer_id: number, admin_id: number }): Observable<any> {
    return this.http.post(`/take_over/`, payload);
  }

  // No paginated request
  getSalesRep(): Observable<Array<SalesRepModel>> {
    return this.http.get<Array<SalesRepModel>>(`/sales_rep_nopaginated_readonly/`);
  }

  patchCustomerPlanChanges(target: CustomerPlanModel): Observable<any> {
    target.appointment_date_from = this.dateFactory.transformFormDate(target.appointment_date_from_date, target.appointment_time_from_date);
    target.appointment_date_to = this.dateFactory.transformFormDate(target.appointment_date_to_date, target.appointment_time_to_date);
    return this.http.patch(`/customer_plan_manage/${target.id}/`, {
      id: target.id,
      appointment_date_from: target.appointment_date_from,
      appointment_date_to: target.appointment_date_to,
      addons_allowed: target.addons_allowed
    });
  }

  getUserTransactions(page: number, userId): Observable<PaginatedResponseModel<TransactionModel>> {
    return this.http.get<PaginatedResponseModel<TransactionModel>>(`/transaction_readonly/?page=${page}&master=true`,
      {params: {customer_id: userId}});
  }

  deleteOneSeat(customerPlanSeat: number): Observable<any> {
    return this.http.delete(`/customer_plan_seat_add_delete/${customerPlanSeat}`);
  }

  getUserPrice(customerPlanid: number): Observable<Array<PriceModel>> {
    return this.http.get<Array<PriceModel>>(`/filtered_price_type_price/?customer_plan=${customerPlanid}`);
  }

  // tslint:disable-next-line:variable-name
  updatePrice(id: number, _price: string): Observable<any> {
    return this.http.patch(`/customer_plan_seat_add_delete/${id}`, {
      price: _price
    });
  }

  getReservedSeats(customerId: number): Observable<Array<SeatEventStatusCustomerModel>> {
    return this.http.get<Array<SeatEventStatusCustomerModel>>(`/customer_reserved_seats/?customer=${customerId}&status=reserved`);
  }

}
