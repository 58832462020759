import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponseModel } from '../../shared/models';
import { SalesRepModel } from '../shared/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesRepService {

  constructor(private http: HttpClient) {
  }

  getPaginatedSalesRep(page: number): Observable<PaginatedResponseModel<SalesRepModel>> {
    return this.http.get<PaginatedResponseModel<SalesRepModel>>(`/staff_readonly/?page=${page}`);
  }

  getFilteredSalesRep(page: number, filter: any, headers?): Observable<PaginatedResponseModel<SalesRepModel>> {
    return this.http.get<PaginatedResponseModel<SalesRepModel>>(`/staff_readonly/?page=${page}`, {params: filter, headers});
  }

  patchSalesRep(target: SalesRepModel): Observable<SalesRepModel> {
    return this.http.patch<SalesRepModel>(`/user_manage/${target.id}/`, target);
  }

  postSalesRep(target: SalesRepModel): Observable<SalesRepModel> {
    return this.http.post<SalesRepModel>(`/user_manage/`, target);
  }

  deleteSalesRep(id: number): Observable<SalesRepModel> {
    return this.http.delete<SalesRepModel>(`/user_manage/${id}/`);
  }
}
