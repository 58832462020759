import { Component, OnInit, Input } from '@angular/core';
import { VerticalBarChartModel } from './vertical-bar-chart.model';
import { ChartConfigService } from '..';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: VerticalBarChartModel;

  constructor(private chartConfigService: ChartConfigService) { }

  ngOnInit() {
    this.chartBaseConfig = this.chartConfigService.setVerticalBarChartConfig(this.data);
  }

}
