import { Component, OnInit } from '@angular/core';
import { FlowControlService } from '../services';
import { DvmService } from '../../shared/services/dvm.service';
import { LoadHandler } from '../handlers/load.handler';
import { PopoverCreateHandler } from '../handlers/popover-create.handler';
import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { ClickHandler } from '../handlers/click.handler';
import { SelectionAreaHandler } from '../handlers/selection-area.handler';

@Component({
  selector: 'app-inventory-landing',
  templateUrl: './inventory-landing.component.html',
  styleUrls: ['./inventory-landing.component.scss'],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    SelectionAreaHandler
  ]
})
export class InventoryLandingComponent implements OnInit {

  constructor(private flowControlService: FlowControlService,
              private dvmService: DvmService,
              private clickHandler: ClickHandler,
              private loadHandler: LoadHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private selectionAreaHandler: SelectionAreaHandler) { }

  ngOnInit() {
    this.dvmService.restartDVM();
  }

  get step(): string {
    return this.flowControlService.flowStep;
  }

  get legendsMode(): string {
    return this.flowControlService.colorsmode;
  }


}
