import { Injectable } from '@angular/core';
import { DvmService } from '../../shared/services/dvm.service';

@Injectable()
export class GeneralHandler {
        constructor(private event,
                    protected dvmService: DvmService) {
                this.dvmService.subscribeHandler(event, this.handle.bind(this));
        }

        protected handle(...args){}
}