import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { RegisterService } from '../services/register.service';
import { UserModel } from '../shared/models';
import { UserRegisterModel } from '../shared/models/user-register.model';
import { CustomerPlanModel, PlanModel } from '../../shared/models';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidateFormService } from 'src/app/services/validate-form.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-customer-plan-modal',
  templateUrl: './customer-plan-modal.component.html'
})
export class CustomerPlanModalComponent implements OnInit {

  user: UserModel | UserRegisterModel;
  customerplan: CustomerPlanModel;
  planList: Observable<Array<PlanModel>>;
  addons: boolean;
  seatObjTemplate: { id: string, section: string, row: string, seat: string };
  availabilityObject: { sections: Array<string>, row: any, seat: Array<string> };
  listrow: Array<string>;
  closeReason: 'cancel' | 'add';
  errors: boolean;
  responseCustomerPlan: CustomerPlanModel;

  constructor(public bsModalRef: BsModalRef,
              private registerService: RegisterService,
              private formValidatorService: ValidateFormService,
              private notificationService: NotificationService) {
    this.customerplan = {addons_allowed: 0, appointment_date_to: '2020-01-24T05:00:00Z', seatList: []} as CustomerPlanModel;
    this.seatObjTemplate = {id: undefined, section: undefined, row: undefined, seat: undefined};
    this.availabilityObject = {sections: [], row: {}, seat: []};
    this.addons = false;
    this.errors = false;
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.planList = this.registerService.getPlans();
  }

  removeThisSeat(mmcseat: string): void {
    const operation = (element) => element.id === mmcseat;
    const index = this.customerplan.seatList.findIndex(operation);
    this.customerplan.seatList.splice(index, 1);
  }

  addSeatToCustomerPlan(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.seatObjTemplate.id = 'S_' + this.seatObjTemplate.section + '-' + this.seatObjTemplate.row + '-' + this.seatObjTemplate.seat;
    this.customerplan.seatList.push(this.seatObjTemplate);
    this.seatObjTemplate = this.getSeatObjTemplate();
  }

  onSubmit(form: NgForm): void {
    this.customerplan.user = this.user;
    if (form.invalid) {
      this.errors = true;
      return;
    }
    this.errors = false;
    this.registerService.postCreateCustomerPlan(this.customerplan).subscribe(
      response => {
        this.responseCustomerPlan = response;
        this.notificationService.showSuccess('Plan created');
      }, error => {
        this.notificationService.showError('Could not create plan');
        console.log(error);
      }
    );
    this.onClose('add');
  }

  onSelectPlan(): void {
    this.registerService.getPlanAvailability(this.customerplan.plan.id).subscribe(
      response => {
        Object.keys(response).forEach((mmcid) => {
          const sect = mmcid.split('_');
          this.availabilityObject.sections.push(sect[1]);
        });
      }
    );
  }

  onSelectSection(): void {
    this.availabilityObject.row = {};
    this.availabilityObject.seat = [];
    const section = 'S_' + this.seatObjTemplate.section;
    this.registerService.getPlanAvailabilitySection(this.customerplan.plan.id, section).subscribe(
      response => {
        Object.keys(response).forEach(
          (mmcid) => {
            // tslint:disable-next-line:variable-name
            this.seatObjTemplate.row = '';
            this.seatObjTemplate.seat = '';
            const mmcidArray = mmcid.split('-');
            if (!this.availabilityObject.row.hasOwnProperty(mmcidArray[1])) {
              this.availabilityObject.row[parseInt(mmcidArray[1], 10)] = [];
            }
            this.availabilityObject.row[parseInt(mmcidArray[1], 10)].push(parseInt(mmcidArray[2], 10));
          }
        );
        this.listrow = Object.keys(this.availabilityObject.row).sort();
      }
    );
  }

  onSelectRow(): void {
    this.availabilityObject.seat = this.availabilityObject.row[this.seatObjTemplate.row].sort();
  }

  private getSeatObjTemplate(): { id: string, section: string, row: string, seat: string } {
    return {id: undefined, section: undefined, row: undefined, seat: undefined};
  }

  onClose(reason: 'cancel' | 'add'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

}
