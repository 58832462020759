import { Component, OnInit, Input } from '@angular/core';
import { ChartConfigService } from '../chart-config.service';
import { HorizontalBarModel } from './horizontal-bar.model';

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnInit {
  @Input() data;
  chartBaseConfig: HorizontalBarModel;
  constructor(private chartConfigService: ChartConfigService) {}

  ngOnInit() {
    this.chartBaseConfig = this.chartConfigService.setHorizontalBarChartConfig(this.data);
  }

}
