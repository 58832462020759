import { Component, Input, OnInit } from '@angular/core';
import { InventoryListModel } from '../shared/models/inventory-list.model';
import { BsModalRef } from 'ngx-bootstrap';
import { InventoryService } from '../services';
import { NotificationService } from '../../services/notification.service';
import { BackofficeUser } from '../../shared';
import { UserService } from '../../user-management/services';
import { PlanSeatCommentModel } from '../../pricing-management/shared';
import { SideInterfaceComponent } from '../side-interface/side-interface.component';

@Component({
  selector: 'app-plan-seat-comment-modal',
  templateUrl: './plan-seat-comment-modal.component.html',
  styleUrls: ['./plan-seat-comment-modal.component.scss']
})
export class PlanSeatCommentModalComponent implements OnInit {

  @Input() seats: InventoryListModel[];
  @Input() user: BackofficeUser;

  constructor(public bsModalRef: BsModalRef, private userService: UserService,
              private inventoryService: InventoryService,
              private notificationService: NotificationService) { }

  ngOnInit() {
  }

  saveComment(htmlTextAreaRef: HTMLTextAreaElement): void {
    const comment = htmlTextAreaRef.value;
    // seats from only one plan should be selected
    const planToUpdate = this.seats[0].plan_id;
    this.inventoryService.changeSeatComment(this.seats.map(seat=>seat.id), planToUpdate, comment).subscribe(
      data=> {
        this.notificationService.showSuccess('Seat comment updated');
        for (const inventoryInfo of this.seats) {
          inventoryInfo.comments = comment ? [{comment, plan: planToUpdate, seat: inventoryInfo.id}] : [];
        }
        this.close();
      }
    )
  }

  close(): void {
    this.bsModalRef.hide();
  }

  get seatsFormated() {
    return this.seats.map(_ => _.id).join(', ');
  }

  isSaveCommentShown() {
    return this.user.type === 'admin' || this.user.type === 'management';
  }
}
