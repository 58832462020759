import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DjangoSessionAuthenticationService } from './django-session-authentication.service';
import { HttpClientModule } from '@angular/common/http';
import { error } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: DjangoSessionAuthenticationService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.getUserLogged().then((user) => {
      if (!user) {
        this.router.navigate(['/login']);
        return false;
      // } else if (state.url === '/price/price-management' || state.url === '/price/price-assign' && !user.administrator) {
      //   return false;
      } else if (user.type === 'customer') {
        return false;
      } else {
        return true;;
      }
    }, Error(
      // @ts-ignore
      (e) => {
      return false;
    }));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.canActivate(next, state);
    return this.authService.canActivate();
  }
}
