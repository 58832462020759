import { Component, OnInit, Input } from '@angular/core';
import { SeatTransactionModel } from '../../shared/models';

@Component({
  selector: 'app-transaction-table-body',
  templateUrl: './transaction-table-body.component.html',
  styleUrls: ['./transaction-table-body.component.scss']
})
export class TransactionTableBodyComponent implements OnInit {

  @Input() seatTransactionList: Array<SeatTransactionModel>;
  @Input() tableTitle: string;

  constructor() { }

  ngOnInit() {
  }

  isDisabled(array: Array<SeatTransactionModel>): boolean {

    if ( !Array.isArray(array) || !array.length ) {
      return false;
    } else {
      return true;
    }
  }

  getseatSection(seatID: string): string {
    return seatID.split('-')[0].split('_')[1];
  }

  getseatRow(seatID: string): string {
    return seatID.split('-')[1];
  }

  getseatSeat(seatID: string): string {
    return seatID.split('-')[2];
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }

}
