import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { PricingService } from '../services';
import { NgForm } from '@angular/forms';
import { PriceTypeModel } from '../../shared/models';
import { ValidateFormService } from '../../services';
import { DjangoSessionAuthenticationService } from '../../auth';

@Component({
  selector: 'app-price-type-modal-edit',
  templateUrl: './price-type-modal-edit.component.html'
})
export class PriceTypeModalEditComponent implements OnInit {

  priceType: PriceTypeModel;
  defaultPriceType: PriceTypeModel;
  closeReason: 'cancel' | 'create' | 'update';
  process: 'update' | 'create';

  constructor(public bsModalRef: BsModalRef, private pricingService: PricingService, private auth: DjangoSessionAuthenticationService,
              private formValidator: ValidateFormService) {
    this.process = 'create';
    this.defaultPriceType = {} as PriceTypeModel;
  }

  ngOnInit() {
    if (this.priceType) {
      this.defaultPriceType = this.priceType;
      this.process = 'update';
    }
  }

  onSubmitPriceType(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.priceType = JSON.parse(JSON.stringify(this.defaultPriceType));

    if (this.process === 'update') {
      // this.pricingService.patchPriceType(this.priceType).subscribe(
      //   response => {
      //     this.onCloseModal('update');
      //   }
      // );
    } else if (this.process === 'create') {
      this.pricingService.postNewPriceType(this.priceType).subscribe(
        response => {
          this.priceType = response;
          this.onCloseModal('create');
        }
      );
    }


  }

  onCloseModal(reason: 'cancel' | 'create' | 'update'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }
}
