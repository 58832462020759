import { Component, OnInit, Inject } from '@angular/core';
import { DvmService } from '../../services/dvm.service';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { FlowControlService } from '../../../inventory-management/services/index';

@Component({
  selector: 'app-map-topbar',
  templateUrl: './map-topbar.component.html',
  styleUrls: ['./map-topbar.component.scss']
})
export class MapTopbarComponent implements OnInit {

  constructor(@Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              private dvmService: DvmService,
              private flowService: FlowControlService) { }

  ngOnInit() {
  }

  get currentSection() {
    if (this.dvmService.viewer) {
      return this.dvmService.viewer.getMapId().split('-')[0].split('_')[1];
    }
  }

  get isStep2() {
    if (this.dvmService.viewer && this.dvmService.viewer.getMapId()) {
      return this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id;
    }
    return false;
  }

  goBack() {
    this.flowService.sectionSelected = null;
    this.flowService.setSectionData(null);
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    this.dvmService.loadMap(loadOptions);
  }

}
