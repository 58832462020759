import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing/landing.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProgressbarModule } from 'ngx-bootstrap';
import { ChartsManagementModule } from '../charts/charts-management.module';


@NgModule({
  declarations: [
    LandingComponent
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    NgxChartsModule,
    ProgressbarModule.forRoot(),
    ChartsManagementModule
  ]
})
export class LandingModule { }
