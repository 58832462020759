import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UserService } from '../services';
import { SalesRepModel } from '../shared/models';
import { NgForm } from '@angular/forms';
import { MessagingService } from 'src/app/shared';
import { ValidateFormService } from 'src/app/services/validate-form.service';
import { SalesRepService } from '../services/sales-rep.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-sales-rep-modal-details',
  templateUrl: './sale-rep-modal-details.component.html'
})
export class SalesRepModalDetailsComponent implements OnInit {

  user: SalesRepModel;
  defaultrep: SalesRepModel;
  process: 'register' | 'update';
  action: 'close' | 'update';

  constructor(public bsModalRef: BsModalRef,
              private salesRepService: SalesRepService,
              private messageService: MessagingService,
              private notificationService: NotificationService,
              public formValidatorService: ValidateFormService) {
    this.defaultrep = {} as SalesRepModel;
    this.process = 'register';
  }

  ngOnInit() {
    this.defaultrep.type = 'sales_person';
    if (this.user) {
      this.defaultrep = this.user;
      this.process = 'update';
    }
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.user = JSON.parse(JSON.stringify(this.defaultrep));

    if (this.process === 'update') {
      this.salesRepService.patchSalesRep(this.defaultrep).subscribe(
        data => {
          this.onClose('update');
          this.notificationService.showSuccess('User has been updated');
        }, error => {
          this.notificationService.showError('There was an error updating the user');
        }
      );
    } else if (this.process === 'register') {
      this.defaultrep.created_by = 1;
      this.defaultrep.updated_by = 1;
      this.defaultrep.venue = 1;
      this.salesRepService.postSalesRep(this.defaultrep).subscribe(
        data => {
          this.onClose('update');
          this.notificationService.showSuccess('User has been created');
        }, error => {
          this.notificationService.showError('There was an error creating the user');
        }
      );
    } else {
    }
  }

  onDelete(): void {
    const title = 'Delete';
    const message = 'Do you really want to delete Sales Rep. ' + this.defaultrep.first_name;
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => {
      this.salesRepService.deleteSalesRep(this.defaultrep.id).subscribe(
        success => {
          this.onClose('update');
          this.notificationService.showSuccess('User has been deleted');
        },
        error => {
          this.notificationService.showError('There was an error deleting the user');
        }
      );
    }, closeBtnName);
  }

  onClose(close: 'update' | 'close'): void {
    this.action = close;
    this.bsModalRef.hide();
  }
}
