import { Component, OnInit, Input } from '@angular/core';
import { PieChartModel } from './pie-chart.model';
import { ChartConfigService } from '../chart-config.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: PieChartModel;
  constructor(private chartConfigService: ChartConfigService) {}

  ngOnInit() {
    console.log(this.data);
    this.chartBaseConfig = this.chartConfigService.setPieChartConfig(this.data);
  }


}
