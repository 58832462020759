import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DVM_CONFIG } from '../../configuration/dvm.configuration';
import { DVMConfiguration } from '../../configuration/dvm-configuration.model';
import { APP_CONFIG, BackofficeConfigurationModel } from '../../configuration';
import { InventoryService } from '../services';
import { NgForm } from '@angular/forms';
import { InventoryListFilterModel } from '../shared';
import { BackofficeUser, MessagingService, PlanModel } from '../../shared';
import { saveAs } from 'file-saver';
import { InventoryListModel } from '../shared/models/inventory-list.model';
import { BsModalRef, BsModalService, ModalOptions, PageChangedEvent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { PlanSeatCommentModalComponent } from '../plan-seat-comment-modal/plan-seat-comment-modal.component';
import { DjangoSessionAuthenticationService } from '../../auth';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MultiSelectComponent } from 'ng-multiselect-dropdown/multiselect.component';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { forkJoin } from 'rxjs';
import {
  InventoryChangeStatusModalComponent
} from "../inventory-change-status-modal/inventory-change-status-modal.component";
import {
  InventoryLockUnlockModalComponent
} from "../inventory-lock-unlock-modal/inventory-lock-unlock-modal.component";

// tslint:disable-next-line:class-name
interface columnOrder {
  isActive: boolean;
  direction: 'ASC' | 'DESC';
  sortFunc?: (a: any, b: any) => number;
}

interface ItemMultiEditHolderModel {
  [key: string]: boolean;
}

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
@HostListener('window:scroll', [])
export class InventoryListComponent implements OnInit {

  @ViewChild('rowSelector', { static: false }) rowSelectorElement: ElementRef;
  @ViewChild('multiSelect', { static: false }) multiSelect: MultiSelectComponent;
  @ViewChild('FilterForm', { static: false }) form: NgForm;
  user: BackofficeUser;
  filter = {} as InventoryListFilterModel;
  selectedPlanId: number;
  windowScrolled: boolean;
  premiumPlan: PlanModel;
  planList: Array<PlanModel>;
  sectionList: Array<string> = [];
  paginationVisible: boolean;
  // plansObservable$: Observable<Array<PlanModel>>;
  pristineInventoryObject: {
    list: Array<InventoryListModel>;
  };
  showToTop: boolean;
  inventoryList: Array<InventoryListModel>;
  inventoryListOnView: Array<InventoryListModel>;
  bsModalRef: BsModalRef;
  start = true;
  // Pagination variables
  perpage: string;
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  // Multi selector vars
  // status
  dropdownListStatus: Array<ListItem> = [];
  selectedItemsStatus: Array<ListItem> = [];
  multiDropdownPristineStatus: boolean;
  dropdownSettingsStatus: IDropdownSettings = {};
  // plan
  selectedItemsPlan: Array<PlanModel> = [];
  multiDropwdownPristinePlan: boolean;
  dropdownSettingsPlan: IDropdownSettings = {};
  columnSortObject: { [key: string]: columnOrder } = {
    plan: { isActive: false, direction: 'ASC' },
    seat_section: { isActive: false, direction: 'ASC' },
    seat_row: {
      isActive: false, direction: 'ASC', sortFunc: (a: any, b: any) => {
        const number1 = parseInt(a.seat_row, 10);
        const number2 = parseInt(b.seat_row, 10);
        return number1 < b.seat_row ? 1 : number1 > number2 ? -1 : 0;
      }
    },
    seat: {
      isActive: false, direction: 'ASC', sortFunc: (a: any, b: any) => {
        const number1 = parseInt(a.seat, 10);
        const number2 = parseInt(b.seat, 10);
        return number1 < number2 ? 1 : number1 > number2 ? -1 : 0;
      }
    },
    fixed_seats: {
      isActive: false, direction: 'ASC', sortFunc: (a: any, b: any) => {
        const number1 = parseInt(a.fixed_seats, 10);
        const number2 = parseInt(b.fixed_seats, 10);
        return number1 < number2 ? 1 : number1 > number2 ? -1 : 0;
      }
    },
    barstools: {
      isActive: false, direction: 'ASC', sortFunc: (a: any, b: any) => {
        const number1 = parseInt(a.barstools, 10);
        const number2 = parseInt(b.barstools, 10);
        return number1 < number2 ? 1 : number1 > number2 ? -1 : 0;
      }
    },
    locked: { isActive: false, direction: 'ASC' },
    status: { isActive: false, direction: 'ASC' },
    account_name: {
      isActive: false, direction: 'ASC', sortFunc: (a: any, b: any) => {
        const valueFirst = (typeof a.customer === 'string' || a.customer.account_name === '') ? 'unavailable' : a.customer.account_name;
        const valueSecond = (typeof b.customer === 'string' || a.customer.account_name === '') ? 'unavailable' : b.customer.account_name;
        return valueFirst.localeCompare(valueSecond);
      }
    },
    price: { isActive: false, direction: 'ASC' },
  };

  allOption: ListItem = { id: 'all', text: 'All', isDisabled: false };

  // item multi selection variables
  itemMultiEditHolder: ItemMultiEditHolderModel;

  constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
              private toastr: NotificationService,
              private modalService: BsModalService,
              private messageService: MessagingService,
              private authService: DjangoSessionAuthenticationService,
              @Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private route: ActivatedRoute, private router: Router,
              private inventoryService: InventoryService) {
    this.currentPage = 1;
    // this.itemsPerPage = 10; // Same number in API Settings.
    this.inventoryListOnView = [];
    this.inventoryList = [];
    this.pristineInventoryObject = { list: [] };
    // this.showToTop = false;
  }

  ngOnInit() {
    this.initItemsMultiEdit();
    this.configureMultiDropdownStatus();
    this.filter.status = '';
    this.filter.section = '';
    this.filter.group = '0';
    this.paginationVisible = true;
    this.authService.getUserLogged().then((user) => {
      if (typeof user !== 'boolean') {
        this.user = user;
      }
    });
    this.inventoryService.getPlans().subscribe((response) => {
      this.planList = response;
      this.premiumPlan = this.planList.filter((plan) => plan.name.includes('Premier'))[0];
      this.filter.planId = `0`;
      // multi select initialize control as dirty
      this.form.controls.plan.markAsUntouched();
      this.form.controls.plan.markAsPristine();
      // this.init();
    });
    this.dropdownSettingsPlan = {
      singleSelection: false,
      itemsShowLimit: 7,
      enableCheckAll: false,
      allowSearchFilter: false,
      textField: 'name'
    };
  }

  private init(plans: Array<number>, status: Array<string> = null, section: string = null, group: string = null): void {
    this.itemsPerPage = 10; // Same number in API Settings.
    this.showToTop = false;
    this.paginationVisible = true;
    if (this.rowSelectorElement) {
      this.rowSelectorElement.nativeElement.value = '10';
    }
    const planObservables = plans.map(plan => this.inventoryService.getInventoryPlanList(plan));
    forkJoin(planObservables).subscribe(values => {
      let list = [] as Array<InventoryListModel>;
      list = list.concat(...values);
      const cleanList = this.inventoryService.removeSeatFromList(['Lexus Club', 'Lot C', 'Lot W'], list);
      this.sectionList.length = 0;
      this.sectionList = this.inventoryService.getSectionList(cleanList);
      const sorting = (txt1: string, txt2: string) => {
        return txt1.localeCompare(txt2, undefined, { numeric: true, sensitivity: 'base' });
      };
      this.sectionList.sort(sorting);
      this.pristineInventoryObject = {
        list: cleanList
      };
      this.inventoryList = cleanList;
      if (status) {
        this.inventoryList = this.filterByStatus(cleanList, status);
      }
      if ((section) && (section !== '')) {
        this.inventoryList = this.filterBySection(this.inventoryList, section);
      }
      if (group) {
        this.inventoryList = this.groupByAvailable(group);
      }
      this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
    });
  }

  filterByStatus(list: Array<InventoryListModel>, status: Array<string>): Array<InventoryListModel> {
    // fix when we select groups and we manually put available status
    if (status[0] === this.allOption.id) {
      return list;
    }
    return list.filter((obj) => status.includes(obj.status));
  }

  filterBySection(list: Array<InventoryListModel>, section: string): Array<InventoryListModel> {
    if (section === '') {
      return list;
    }
    return list.filter((obj) => obj.seat_section === section);
  }

  onSubmitFilter(form: NgForm): void {
    this.currentPage = 1;
    let passPlan;
    let passStatus;
    let passSection;
    let passGroup;
    const { dirtyPlan, dirtyStatus, dirtySection, dirtyGroup } = this.getDirtyFilter(form);
    if (dirtyPlan) {
      passPlan = this.selectedItemsPlan.map(plan => plan.id);
      form.controls.plan.markAsUntouched();
      form.controls.plan.markAsPristine();
    }
    if (dirtyStatus) {
      passStatus = this.extractStatusFromMultiSelector(this.selectedItemsStatus);
    }
    if (dirtySection) {
      passSection = form.value.section;
    }
    if (dirtyGroup) {
      if (form.value.group !== '0') {
        passGroup = parseInt(form.value.group, 10);
      }
    }
    if (form.value.plan === '0') {
      // si seleccionamos "None" en el selector de plan, hacemos un hard reset
      this.restartForm(form);
    } else {
      this.applyFilter(passPlan, passStatus, passSection, passGroup);
    }
  }

  applyFilter(planId: Array<number>, status: Array<string>, section: string, group: string): void {
    this.initItemsMultiEdit();
    this.resetColumnSort();
    const statusAllisSelected = this.multiSelect.isSelected(this.allOption);
    // if we have some group to filter and status all is selected put available status
    if (statusAllisSelected && this.filter.group !== '0') {
      // this one is used if no plan id
      this.selectedItemsStatus = [this.dropdownListStatus[1]];
      // if plan id
      status = [this.selectedItemsStatus[0].id as string];
    }
    // if we are changing plan, reinit everything
    if (planId) {
      this.init(planId, status, section, group);
    } else {
      // we start always as fresh table, avoid if and elses
      this.resetTable();
      this.inventoryList = this.filterByStatus(this.inventoryList, this.extractStatusFromMultiSelector(this.selectedItemsStatus));
      this.inventoryList = this.filterBySection(this.inventoryList, this.filter.section);
      this.inventoryList = this.groupByAvailable(this.filter.group);
      this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
    }
  }

  getDirtyFilter(form: NgForm) {
    const controls = form.controls;
    const resp = { dirtyStatus: false, dirtyPlan: false, dirtySection: false, dirtyGroup: false };
    resp.dirtyPlan = controls.plan.dirty;
    resp.dirtyStatus = (this.selectedItemsStatus.length > 0); // TODO arreglar el dirty
    resp.dirtySection = controls.section.dirty;
    resp.dirtyGroup = controls.group.dirty;
    return resp;
  }

  restartForm(form: NgForm = null): void {
    this.initItemsMultiEdit();
    this.filter = {
      planId: `0`,
      status: '',
      section: '',
      group: '0'
    };
    form.controls.plan.markAsDirty();
    form.controls.section.markAsPristine();
    this.multiDropdownPristineStatus = true;
    this.itemsPerPage = 10;
    this.showToTop = false;
    this.inventoryListOnView.length = 0;
    this.inventoryList.length = 0;
    // reset de column order
    this.resetColumnSort();
    // Al hacer reset desmarcamos todas las opciones
    const list = JSON.parse(JSON.stringify(this.multiSelect.selectedItems));
    for (const item of list) {
      this.multiSelect.removeSelected(item);
    }
    // this.init();
  }

  private resetTable(): void {
    this.currentPage = 1;
    this.inventoryList = this.pristineInventoryObject.list;
    this.inventoryListOnView = this.inventoryList.slice(0, 10);
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * this.itemsPerPage;
    const endItem = event.page * this.itemsPerPage;
    this.inventoryListOnView = this.inventoryList.slice(startItem, endItem);
  }

  onChangeItemsNumber(target): void {
    const newQuantity = target.target.value;
    this.itemsPerPage = (isNaN(parseInt(newQuantity, 10))) ? this.inventoryList.length : parseInt(newQuantity, 10);
    this.paginationVisible = (newQuantity !== 'MAX');
    this.showToTop = (newQuantity === 'MAX' || newQuantity === '50');
    this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
  }

  exportList2CSV(): void {
    const csv = this.toCSV();
    const date = new Date().toLocaleString();
    const file = new File([csv], `inventory_list_${date}.csv`, { type: 'text/plain;charset=utf-8' });
    saveAs(file);
  }

  exportListNewFormat() {
    this.inventoryService.getInventoryExportListNewFormatCSV(this.selectedItemsPlan[0]).subscribe(
      data => {
        saveAs(data, `${this.selectedItemsPlan[0].name}_newformat.csv`);
      }
    );
  }

  // initially one plan at a time
  isInventoryListNewFormatExportButtonShown() {
    return this.selectedItemsPlan.length === 1 && this.selectedItemsPlan[0].name.includes('Premier');
  }

  toCSV(): string {
    let csv = '';
    const separator = ',';
    // tslint:disable-next-line:max-line-length
    const headers = `Plan${separator}Section${separator}Row${separator}Seat${separator}Fixed Seats${separator}Barstools${separator}Locked${separator}Status${separator}Owner${separator}` +
      `Account Number${separator}Price${separator}Price_SCALE${separator}First Name${separator}Last Name${separator}Comments\n`;
    csv += headers;
    for (const line of this.inventoryList) {
      const customer = (typeof line.customer !== 'string' && line.customer) ? line.customer
        : { account_name: '', username: '', first_name: '', last_name: '' };
      const note = (line.comments.length > 0) ? line.comments[0].comment : '';
      // tslint:disable-next-line:max-line-length
      csv += `${line.plan_name}${separator}${line.seat_section}${separator}${line.seat_row}${separator}${line.seat}` +
        `${separator}${line.fixed_seats}${separator}${line.barstools}${separator}${line.locked}${separator}` +
        `${line.status}${separator}${customer.account_name}${separator}${customer.username}${separator}${line.price}${separator}` +
        `${line.price_type}${separator}${customer.first_name}${separator}${customer.last_name}${separator}${note}\n`;
    }
    return csv;
  }

  go2Top(): void {
    document.getElementsByTagName('header')[0].scrollIntoView({ behavior: 'smooth' });
  }

  replaceLowerBar(text: string) {
    if (text.includes('_')) {
      return text.replace('_', ' ');
    }
    return text;
  }

  onSelectStatus(item: ListItem): void {
    if (item.id !== 'all' && this.multiSelect.isSelected(this.allOption)) {
      this.multiSelect.removeSelected(this.allOption);
    }
    if (item.id === 'all') {
      const listSel = JSON.parse(JSON.stringify(this.multiSelect.selectedItems));
      for (const it of listSel) {
        if (it.id !== 'all') {
          this.multiSelect.removeSelected(it);
        }
      }
      // this.multiSelect.addSelected(this.allOption);
    }
    this.multiDropdownPristineStatus = false;
  }

  onDeselectStatus(item: ListItem): void {
    if (this.selectedItemsStatus.length === 0 && !this.multiSelect.isSelected(this.allOption)) {
      this.multiSelect.addSelected(this.allOption);
    }
  }

  // multi-select drop down shit
  private configureMultiDropdownStatus(): void {
    this.multiDropdownPristineStatus = true;
    this.dropdownListStatus = [
      this.allOption,
      { id: 'available', text: 'Available' },
      { id: 'reserved', text: 'Reserved' },
      { id: 'owned', text: 'Sold' },
      { id: 'on_hold', text: 'On Hold' },
      { id: 'available_internal', text: 'Available Internal' },
      { id: 'internal_hold', text: 'Internal Hold' }];
    this.selectedItemsStatus.push(this.allOption);
    this.dropdownSettingsStatus = {
      singleSelection: false,
      itemsShowLimit: 7,
      enableCheckAll: false,
      allowSearchFilter: false
    };
  }

  // Crea un array de estados extraidos del array de objetos
  private extractStatusFromMultiSelector(list: Array<ListItem>): Array<string> {
    const statusList = [];
    for (const item of list) {
      statusList.push(item.id);
    }
    return statusList;
  }

  orderColumn(colName: string, direction = 'ASC'): void {
    if (this.pristineInventoryObject.list.length === 0) {
      return;
    }
    this.resetColumnSort();
    this.columnSortObject[colName].isActive = true;
    const list = this.inventoryList.slice();
    let sortedList: Array<InventoryListModel>;
    const sortFunc = (a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0;
    if (direction === 'DESC') {
      this.columnSortObject[colName].direction = 'ASC';
      sortedList = list.sort(this.columnSortObject[colName].sortFunc || sortFunc);
    } else {
      this.columnSortObject[colName].direction = 'DESC';
      sortedList = list.sort(this.columnSortObject[colName].sortFunc || sortFunc).reverse();
    }
    this.inventoryList = sortedList;
    this.inventoryListOnView = sortedList.slice(0, this.itemsPerPage);
  }

  private resetColumnSort(): void {
    Object.keys(this.columnSortObject).forEach((item) => {
      this.columnSortObject[item].direction = 'ASC';
      this.columnSortObject[item].isActive = false;
    });
  }

  pretifyOwner(item: any): string {
    if (typeof item === 'string') {
      return item;
    } else {
      return (item.account_name === '') ? 'unavailable' : item.account_name;
    }
  }

  groupByAvailable(groupNumber: string) {
    if (groupNumber === '0') {
      return this.inventoryList;
    }
    // inventory list is already filtered by section, availability and plan
    return this.inventoryService.groupByAvailable(this.inventoryList, groupNumber);
  }

  // add/change/remove actions
  changeStatus(seatInfo: InventoryListModel[]) {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        seats: seatInfo
      }
    };
    this.bsModalRef = this.modalService.show(InventoryChangeStatusModalComponent, modalConfig);
  }

  // add/change/remove actions
  addEditComment(seatsInfo: InventoryListModel[]): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        seats: seatsInfo,
        user: this.user
      }
    };
    this.bsModalRef = this.modalService.show(PlanSeatCommentModalComponent, modalConfig);
  }

  lockUnlock(seatInfo: InventoryListModel[]) {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        seats: seatInfo
      }
    };
    this.bsModalRef = this.modalService.show(InventoryLockUnlockModalComponent, modalConfig);
  }

  // we check if more than one plan present
  private preEditMultipleCheckMoreThanOnePlan() {
    const inventoryItemMultiEditLocal = this.inventoryItemMultiEdit;
    const samePlanCount = inventoryItemMultiEditLocal.filter(item => item.plan_id === inventoryItemMultiEditLocal[0].plan_id).length;
    // if the amount of items found for the first item plan is different than the total length, there is more than one plan
    if (samePlanCount !== inventoryItemMultiEditLocal.length) {
      this.messageService.info('More than one plan', 'You can only edit multiple seats within the same plan');
      return undefined;
    }
    return inventoryItemMultiEditLocal
  }

  onEditMultipleStatus() {
    const items = this.preEditMultipleCheckMoreThanOnePlan();
    if (items) {
      const itemsWithAllowedStatus = items.find(item => item.status !== 'available' && item.status !== 'internal_hold' && item.status !== 'available_internal');
      if (itemsWithAllowedStatus) {
        this.messageService.info('status not allowed', 'You can only edit seats with status available, available internal or internal hold')
        return;
      }
      this.changeStatus(items);
    }
  }

  onEditMultipleComments() {
    const items = this.preEditMultipleCheckMoreThanOnePlan();
    if (items) {
      this.addEditComment(items);
    }
  }

  onLockUnlockMultiple() {
    const items = this.preEditMultipleCheckMoreThanOnePlan();
    if (items) {
      this.lockUnlock(items);
    }
  }

  initItemsMultiEdit() {
    this.itemMultiEditHolder = {};
  }

  // we get all the keys that have value true
  get itemMultiEditKeys() {
    return Object.entries(this.itemMultiEditHolder).filter(([_, v]) => v).map(([k, _]) => k);
  }

  // for all the keys with value true we find the corresponding element, we search by id and by plan, the key is created
  // in getItemMultiEditHolderKey
  get inventoryItemMultiEdit() {
    return this.itemMultiEditKeys.map(key => this.inventoryList.find(seat => seat.id === key.split('#')[0] && seat.plan_id === parseInt(key.split('#')[1])));
  }

  protected readonly Object = Object;

  // copied on inventory map component as the permissions are the same
  isChangeStatusShown(inventoryItem: InventoryListModel) {
    let show = false;
    let seatStatus = inventoryItem.status;
    if (this.user.type === 'admin') {
      if (seatStatus === 'available' || seatStatus === 'internal_hold' || seatStatus === 'available_internal') {
        show = true;
      }
    }
    return show;
  }

  // needed to be separated because there are different rules for multi edit shown
  isChangeStatusShownMultiple() {
    return this.user.type === 'admin'
  }

  isChangeCommentShown() {
    return this.user.type === 'admin' || this.user.type === 'management';
  }

  isLockUnlockShown() {
    return this.user.type === 'admin';
  }

  // we need composite keys, item id and plan, if not, when we select two plans with repeated seats we only grab the
  // first, ie, if we select full season and half season the S_PR1-1-1 exists in both, and we only grab the first one
  getItemMultiEditHolderKey(item: InventoryListModel) {
    return `${item.id}#${item.plan_id}`;
  }
}
