import { Component, Input, OnInit } from '@angular/core';
import { InventoryListModel } from "../shared/models/inventory-list.model";
import { BsModalRef } from "ngx-bootstrap/modal";
import { InventoryService } from "../services";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: 'app-inventory-lock-unlock-modal',
  templateUrl: './inventory-lock-unlock-modal.component.html',
  styleUrls: ['./inventory-lock-unlock-modal.component.scss']
})
export class InventoryLockUnlockModalComponent implements OnInit {

  @Input() seats: InventoryListModel[];
  lockUnlock: 'lock' | 'unlock';
  constructor(public bsModalRef: BsModalRef, private inventoryService: InventoryService,
              private notificationService: NotificationService,) { }

  ngOnInit() {
  }

  close() {
    this.bsModalRef.hide();
  }

  onSaveLockUnlock(): void {
    let method = 'lockSeat';
    let lockStatus = 'Y';
    if (this.lockUnlock === 'unlock') {
      method = 'unlockSeats';
      lockStatus = 'N';
    }
    // mini preparation for multi change status
    let seatList = [];
    for (const inventoryInfo of this.seats) {
      seatList.push(inventoryInfo.id);
    }
    // seats from only one plan should be selected
    this.inventoryService[method](seatList, this.seats[0].plan_id).subscribe(
      data => {
        this.notificationService.showSuccess(`Seats ${this.lockUnlock}`);
        for (const inventoryInfo of this.seats) {
          inventoryInfo.locked = lockStatus
        }
        this.close();
      }
    );
  }

  get seatsFormated() {
    return this.seats.map(_ => _.id).join(', ');
  }

}
