import { Component, OnInit } from '@angular/core';
import { UserService } from '../services';
import { BsModalRef } from 'ngx-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { NgForm } from '@angular/forms';
import { RegisterService } from '../services/register.service';

@Component({
  selector: 'app-customer-plan-new-seat-modal',
  templateUrl: './customer-plan-new-seat-modal.component.html',
  styleUrls: ['./customer-plan-new-seat-modal.component.scss']
})
export class CustomerPlanNewSeatModalComponent implements OnInit {

  planId: number;
  customerPlan: number;
  closeReason: 'cancel' | 'add';
  seatObjTemplate: { id: string, section: string, row: string, seat: string, ga?: boolean };
  availabilityObject: { sections: Array<string>, row: any, seat: Array<string> };
  listrow: Array<string>;
  customerPlanSeat: any;
  seatType: 'GA' | 'num';

  constructor(public bsModalRef: BsModalRef, private userService: UserService, private notificationService: NotificationService,
              private registerService: RegisterService) {
    this.seatObjTemplate = {id: undefined, section: undefined, row: undefined, seat: undefined};
    this.availabilityObject = {sections: [], row: {}, seat: []};
  }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.registerService.getPlanAvailability(this.planId).subscribe(
      response => {
        Object.keys(response).forEach((mmcid) => {
          const sect = mmcid.split('_');
          this.availabilityObject.sections.push(sect[1]);
        });
      }
    );
  }

  onSelectSection(): void {
    this.availabilityObject.row = {};
    this.availabilityObject.seat = [];
    const section = 'S_' + this.seatObjTemplate.section;
    this.registerService.getPlanAvailabilitySection(this.planId, section).subscribe(
      response => {
        Object.keys(response).forEach(
          (mmcid) => {
            // tslint:disable-next-line:variable-name
            this.seatObjTemplate.row = '';
            this.seatObjTemplate.seat = '';
            // tslint:disable-next-line:variable-name
            const mmcid_array = mmcid.split('-');
            const s = (typeof mmcid_array[1] === 'string') ? mmcid_array[1] : parseInt(mmcid_array[1], 10);
            if (!this.availabilityObject.row.hasOwnProperty(mmcid_array[1])) {
              this.availabilityObject.row[s] = [];
            }
            const n = ((typeof mmcid_array[2] === 'string') ? mmcid_array[2] : parseInt(mmcid_array[2], 10));
            this.availabilityObject.row[s].push(n);
            this.availabilityObject.row[s] = this.availabilityObject.row[s].sort();
          });
        this.listrow = Object.keys(this.availabilityObject.row).sort();
      });
  }

  onSelectRow(): void {
    this.availabilityObject.seat = this.availabilityObject.row[this.seatObjTemplate.row].sort();
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    const seat = 'S_' + this.seatObjTemplate.section + '-' + this.seatObjTemplate.row + '-' + this.seatObjTemplate.seat;
    // la ROW de GA siempre 4
    this.registerService.postAddSeat({customer_plan: this.customerPlan, seat}).subscribe(
      response => {
        this.customerPlanSeat = response;
        this.onClose('add');
      },
      error => {
        console.log(error);
        this.notificationService.showSuccess('Seat could not be added');
      }
    );
  }

  onClose(reason: 'cancel' | 'add'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

}
