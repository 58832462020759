import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { NewPriceTypeModel, NewPriceTypePriceModel } from '../shared/models';
import { NgForm } from '@angular/forms';
import { PricingService } from '../services';
import { BackofficeUser, PriceModel } from '../../shared';
import { PriceTypeModel } from '../../shared/models';
import { ValidateFormService } from '../../services';
import { DjangoSessionAuthenticationService } from '../../auth';

@Component({
  selector: 'app-price-type-modal',
  templateUrl: './price-type-modal.component.html'
})
export class PriceTypeModalComponent implements OnInit {

  priceTypeprice: NewPriceTypePriceModel;
  newPriceType: NewPriceTypeModel;
  responsePriceType: PriceTypeModel;
  resultPriceTypePrice: PriceModel;
  process: 'assign' | 'create'; // assign new price for price type on plan, create new PriceType
  userLogged: BackofficeUser | false;
  remove: boolean;
  closeReason: 'cancel' | 'remove' | 'update';

  constructor(public bsModalRef: BsModalRef, private pricingService: PricingService, private auth: DjangoSessionAuthenticationService,
              private formValidator: ValidateFormService) {
    this.process = 'assign';
    this.newPriceType = {} as NewPriceTypeModel;
    this.remove = false;
  }

  ngOnInit() {
    if (!this.priceTypeprice) {
      this.process = 'create';
    }
    this.init();
  }

  private init(): void {
    this.auth.getUserLogged$().subscribe(
      user => {
        this.userLogged = user;
      }
    );
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    // @ts-ignore
    if ('id' in this.userLogged) {
      this.priceTypeprice.updated_by = this.userLogged.id;
      this.priceTypeprice.created_by = this.userLogged.id;
    }

    this.pricingService.postNewPriceTypePrice(this.priceTypeprice).subscribe(
      response => {
        this.resultPriceTypePrice = response;
        this.onCloseModal('update');
      }, error => {
      }
    );
  }

  deletePrice(): void {
    this.pricingService.deletePriceTypePrice(this.priceTypeprice.price_type_price_id).subscribe(
      response => {
        this.remove = true;
        this.onCloseModal('remove');
      }, error => {
      }
    );
  }

  onCloseModal(reason: 'cancel' | 'remove' | 'update'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }
}
