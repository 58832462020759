import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessagingService } from '../../shared/info-modal';
import { UserService } from '../services';
import { NotificationService } from '../../services/notification.service';
import { BsDatepickerInputDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-customer-plan-seats',
  templateUrl: './customer-plan-seats.component.html',
  styleUrls: ['./customer-plan-seats.component.scss']
})
export class CustomerPlanSeatsComponent implements OnInit {

  @Input() price;
  @Input() seat;
  @Input() seatId;
  @Input() i;
  @Input() customerplanseat;
  @Output() deleted = new EventEmitter<number>();
  seatObj;

  constructor(private messageService: MessagingService, private userService: UserService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.seatObj = this.seat.split('-');
    console.log(this.price);
  }

  removeSeat() {
    this.userService.deleteOneSeat(this.customerplanseat).subscribe(
      response => {
        this.notificationService.showSuccess('Seat is deleted');
        this.deleted.emit(this.i);
      },
      error => {
        this.notificationService.showError('ERROR');
      }
    );
  }

  private whenRemoveSeat(): void {
    const title = 'Alert';
    const message = 'Do you really want to remove this seat?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => { this.removeSeat() }, closeBtnName);
  }

  // When user put the focus in the input
  onFocusPrice(target: HTMLInputElement, numberLocalizedElement: HTMLElement): void {
    target.hidden = false;
    target.focus();
    target.readOnly = false;
    target.classList.remove('input-price');
    target.classList.add('input-price-blur');
    numberLocalizedElement.hidden = true;
    // this.temporal[priceid] = target.value; // Save actual value, request can fail
  }

  // When user leaves the input
  onBlurPrice(target: HTMLInputElement, customerPlanSeatId: number, numberLocalizedElement: HTMLElement): void {
    target.readOnly = true;
    target.classList.add('input-price');
    target.classList.remove('input-price-blur');
    target.hidden = true;
    numberLocalizedElement.hidden = false;
    if (target.classList.contains('ng-pristine')) {
      return;
    }
    this.userService.updatePrice(customerPlanSeatId, target.value).subscribe(
        (response) => {
          this.notificationService.showSuccess('New Price Saved');
        }, error => {
          // target.value = this.temporal[priceid];
          this.notificationService.showError(`New Price couldn't be saved`);
        }
    );
  }


}

/**
 * return this.http.patch(`//${id}`);
 */
