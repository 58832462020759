import { Component, Input, OnInit } from '@angular/core';
import { CustomerPlanModel } from '../../shared/models';
import { UserService } from '../services';
import { ValidateFormService } from '../../services';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { formatDate } from '@angular/common';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { CustomerPlanModalComponent } from '../customer-plan-modal';
import { CustomerPlanNewSeatModalComponent } from '../customer-plan-new-seat-modal/customer-plan-new-seat-modal.component';

@Component({
  selector: 'app-customer-plan',
  templateUrl: './customer-plan.component.html',
  styleUrls: ['./customer-plan.component.scss']
})
export class CustomerPlanComponent implements OnInit {

  @Input() customerplan: CustomerPlanModel;
  @Input() i: number; // Accordeon id
  @Input() accordionToggleSubject;
  @Input() accordionHashSubject;
  accordionHash: any;
  totalSeats: number;
  bsModalRef: BsModalRef;
  appointmentDateFrom;
  appointmentTimeFrom;
  userpriceList

  constructor(private userService: UserService,
              private modalService: BsModalService,
              private notificationService: NotificationService,
              private formValidatorService: ValidateFormService) {
  }

  ngOnInit() {
    this.init();
    this.initializeDate();
  }

  private init(): void {
    console.log(this.customerplan.customer_plan_seats);
    this.totalSeats = this.customerplan.customer_plan_seats.length;
    // this.userService.getUserPrice(this.customerplan.id).subscribe(
    //   (userpriceList) => {
    //     console.log(response);
    //   }
    // );
    // Subscribes to the subject accordionHashSubject to get the accordionHash
    this.accordionHashSubject.subscribe(
      accordionHash => {
        this.accordionHash = accordionHash;
      }
    );
  }

  private initializeDate(): void {
    if (this.customerplan.appointment_date_from) {
      this.appointmentDateFrom = JSON.parse(JSON.stringify(formatDate(this.customerplan.appointment_date_from, 'yyyy-MM-dd', 'en')));
      this.appointmentTimeFrom = JSON.parse(JSON.stringify(formatDate(this.customerplan.appointment_date_from, 'HH:mm', 'en')));
    }
  }

  onSubmit(form: NgForm, customerPlan): void {
    if (form.invalid) {
      return;
    }
    this.userService.patchCustomerPlanChanges(customerPlan).subscribe(
      response => {
        this.notificationService.showSuccess('Plan data has been saved');
        this.initializeDate();
      }, error => {
        console.log(error);
      }
    );
  }

  // Checks if a there is an accordion tab displayed
  isTabDisplayed(id: number) {
    if (this.accordionHash != null) {
      if (this.accordionHash[id] != null) {
        return this.accordionHash[id];
      }
    }
    return false;
  }

  toggleAccordion(accordionId): void {
    // Next on the subject observable to update the accordion
    this.accordionToggleSubject.next(accordionId);
  }

  onDeleteSeat(index: number) {
    this.customerplan.customer_plan_seats.splice(index, 1);
  }

  onNewSeat(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        planId: this.customerplan.plan.id,
        customerPlan: this.customerplan.id
      }
    };
    this.bsModalRef = this.modalService.show(CustomerPlanNewSeatModalComponent, modalConfig);
    const subscription = this.modalService.onHide.subscribe((reason: string) => {
      subscription.unsubscribe();
      if (this.bsModalRef.content.closeReason === 'add') {
        this.customerplan.customer_plan_seats.push(this.bsModalRef.content.customerPlanSeat);
        this.totalSeats = this.customerplan.customer_plan_seats.length;
        this.notificationService.showSuccess('Seat added');
      }
    });
  }

}
