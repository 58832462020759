import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggedMetricModel } from '.';
import { PaginatedResponseModel } from '../shared';
import { InventoryAvailableModel } from './models/inventory-available.model';
import { DeviceCategoryModel } from './models/device-category.model';
import { TimeTransactionModel } from './models/time-transaction.model';
import {
  ActiveUserModel, LoggedInMetricsModel,
  PriceTypeAvailabilityModel,
  PriceTypeInventoryAvailableModel,
  RevenueSaleTypeMetricsModel
} from './models';
import { AddonsPriceTypeModel } from './models/addon-price-type.model';
import { ReleasePriceTypeModel } from './models/release-price-type.model';
import { KeptPriceTypeModal } from './models/kept-price-type.model';

@Injectable({
  providedIn: 'root'
})
export class MetricService {
  headers = new HttpHeaders({ignoreLoadingBar: ''});
  constructor(private http: HttpClient) {
  }

  getConversion(): Observable<LoggedMetricModel> {
    return this.http.get<LoggedMetricModel>(`/metrics/customer/conversion/`, {headers: this.headers});
  }
  getPlanAvailability(): Observable<Array<InventoryAvailableModel>> {
    return this.http.get<Array<InventoryAvailableModel>>(`/metrics/plan/availability/`, {headers: this.headers});
  }
  getPriceTypeAvailability(): Observable<Array<PriceTypeInventoryAvailableModel>> {
    return this.http.get<Array<PriceTypeInventoryAvailableModel>>(`/metrics/plan/availability/price_type/`, {headers: this.headers});
  }
  getDeviceCategory(): Observable<DeviceCategoryModel> {
    return this.http.get<DeviceCategoryModel>(`/metrics/customer/device_category/`, {headers: this.headers});
  }
  getTimeTransactions(): Observable<TimeTransactionModel> {
    return this.http.get<TimeTransactionModel>(`/metrics/customer/goals/`, {headers: this.headers});
  }
  getSeatsPerPriceType(): Observable<any> {
    return this.http.get<any>(`/metrics/seats/added/`, {headers: this.headers});
  }
  getPlanBalance(): Observable<any> {
    return this.http.get<any>(`/metrics/plan/balance/`, {headers: this.headers});
  }
  getActiveUsers(): Observable<ActiveUserModel> {
    return this.http.get<ActiveUserModel>(`/metrics/customer/active/data/`, {headers: this.headers});
  }
  // old routes
  // getAddonsPerPriceType(): Observable<AddonsPriceTypeModel> {
  //   return this.http.get<AddonsPriceTypeModel>(`/metrics/price_type/seats/added/`, {headers: this.headers});
  // }
  // getReleasesPerPriceType(): Observable<ReleasePriceTypeModel> {
  //   return this.http.get<ReleasePriceTypeModel>(`/metrics/price_type/seats/removed/`, {headers: this.headers});
  // }
  // getKeptPerPriceType(): Observable<KeptPriceTypeModal> {
  //   return this.http.get<KeptPriceTypeModal>(`/metrics/price_type/seats/kept/`, {headers: this.headers});
  // }
  getAddonsPerPriceType(plan: any): Observable<AddonsPriceTypeModel> {
    return this.http.get<AddonsPriceTypeModel>(`/metrics/price_type/seats/added/?plan=${plan}`, {headers: this.headers});
  }
  getReleasesPerPriceType(plan: any): Observable<ReleasePriceTypeModel> {
    return this.http.get<ReleasePriceTypeModel>(`/metrics/price_type/seats/removed/?plan=${plan}`, {headers: this.headers});
  }
  getKeptPerPriceType(plan: any): Observable<KeptPriceTypeModal> {
    return this.http.get<KeptPriceTypeModal>(`/metrics/price_type/seats/kept/?plan=${plan}`, {headers: this.headers});
  }

  getAddonsBalance(): Observable<AddonsPriceTypeModel> {
    return this.http.get<AddonsPriceTypeModel>(`/metrics/price_type/balance/addons/`, {headers: this.headers});
  }

  getTotalBalance(): Observable<AddonsPriceTypeModel> {
    return this.http.get<AddonsPriceTypeModel>(`/metrics/price_type/balance/total/`, {headers: this.headers});
  }

  // staples custom
  getTotalRevenueBySaleType(): Observable<RevenueSaleTypeMetricsModel> {
    return this.http.get<RevenueSaleTypeMetricsModel>(`/metrics/staples/revenue/`, {headers: this.headers});
  }

  getUsersLoggedIn(): Observable<LoggedInMetricsModel> {
    return this.http.get<LoggedInMetricsModel>(`/metrics/customer/logged_in/`, {headers: this.headers});
  }


}
