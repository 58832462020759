import { Component, Input, OnInit } from '@angular/core';
import { SeatEventStatusCustomerModel, UserModel } from '../shared';
import { UserService } from '../services';
import { RegisterService } from '../services/register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { BackofficeUser, CustomerPlanModel } from '../../shared';
import { Observable } from 'rxjs/';

@Component({
  selector: 'app-reserved-seats',
  templateUrl: './reserved-seats.component.html',
  styleUrls: ['./reserved-seats.component.scss']
})
export class ReservedSeatsComponent implements OnInit {

  @Input() seatCustomerStatus: SeatEventStatusCustomerModel;
  @Input() customer: UserModel;
  @Input() userLogged: BackofficeUser;

  constructor(private userService: UserService, private route: ActivatedRoute,
              private toast: NotificationService, private router: Router,
              private registerService: RegisterService) { }

  ngOnInit() {
  }

  getSection(): string {
    return this.seatCustomerStatus.seat.split('-')[0].split('_')[1];
  }

  getRow(): string {
    return this.seatCustomerStatus.seat.split('-')[1];
  }

  getSeat(): string {
    return this.seatCustomerStatus.seat.split('-')[2];
  }

  confirmSeat(): void {
  }

  releaseSeat(): void {
    this.registerService.getReleaseSeats(this.seatCustomerStatus.seat, this.seatCustomerStatus.planObj.id)
      .subscribe((response) => {
        console.log(response);
        this.toast.showSuccess('Seat successfully released');
        this.refreshPage();
    });
  }

  onAssignCustomer(): void {
    const customerId = this.customer.id;
    const targetSeat = this.seatCustomerStatus.seat;
    this.userService.getCustomerPlan(customerId).subscribe(
      (customerPlanList) => {
        const result = this.searchCustomerPlan(customerPlanList, this.seatCustomerStatus.planObj.id);
        console.log(result);
        // Si result es false, significa que el usuario no tiene ningun customer plan con ese plan.
        // Le creamos uno de 0 con datos genericos
        // En caso contrario, solo tendremos que añadir la silla al customer plan ya existente
        if (typeof result === 'boolean') { // Customer sin ese plan
          console.log('Nuevo customer plan');
          this.confirmSeatNewCustomerPlan(this.customer, this.seatCustomerStatus.planObj.id, targetSeat);
        } else { // Customer plan con ese customer plan
          console.log('Añadir silla al customer plan existent');
          this.confirmSeatWithCustomerPlan(result, targetSeat);
        }
    });
  }

  private confirmSeatNewCustomerPlan(customer: UserModel, planId: number, seat: string): void {
    this.createNewCustomerPlan(customer, planId).subscribe((newCustomerPlan) => {
      console.log('New Customer Plan:', newCustomerPlan);
      this.confirmSeatWithCustomerPlan(newCustomerPlan, seat);
    });
  }

  createNewCustomerPlan(customer: UserModel, planId: number): Observable<CustomerPlanModel> {
    //   seatList?: Array<{id: string, section: string, row: string, seat: string}>;
    // const listOfSeat: any[] = [];
    // if (Array.isArray(this.assignData.seat)) {
    //   for (const item of this.assignData.seat) {
    //     listOfSeat.push({
    //       id: item.id,
    //       section: item.section,
    //       row: item.seat_row,
    //       seat: item.seat
    //     });
    //   }
    // } else {
    //   listOfSeat.push({
    //     id: this.assignData.seat.id,
    //     section: this.assignData.seat.section,
    //     row: this.assignData.seat.seat_row,
    //     seat: this.assignData.seat.seat
    //   });
    // }
    const genericCp = {
      user: customer,
      plan: { id: planId },
      addons_allowed: 0,
      appointment_date_to: '2021-01-01T05:00:00Z',
      appointment_date_from_date: '2021-01-01',
      appointment_time_from_date: '00:01',
      appointment_date_to_date: '2022-01-01',
      appointment_time_to_date: '00:01',
      seatList: []} as CustomerPlanModel;
    return this.registerService.postCreateCustomerPlan(genericCp);
  }

  // Confirmamos la silla con un customer plan que SI existe.
  private confirmSeatWithCustomerPlan(customerPlan: CustomerPlanModel, seat: string): void {
    const payload = { customer_plan: customerPlan.id, seat: this.seatCustomerStatus.seat };
    this.registerService.postAddSeat(payload).subscribe((response) => {
      this.toast.showSuccess('Successfully assigned seat');
      this.refreshPage();
    });
  }

  searchCustomerPlan(list: Array<CustomerPlanModel>, planWanted: number): CustomerPlanModel | boolean {
    const operation = (element: CustomerPlanModel) => element.plan.id === planWanted;
    const searchResult = list.findIndex(operation);
    if (searchResult !== -1) { return list[searchResult]; }
    return false;
  }

  private refreshPage(): void {
    // save current route first
    let currentRoute = this.router.url;
    currentRoute = currentRoute.split('?')[0];
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }
}
