import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionManagementRoutingModule } from './transaction-management-routing.module';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { TransactionTableBodyComponent } from './transaction-detail/transaction-table-body/transaction-table-body.component';
import { TransactionCommonComponent } from './transaction-common/transaction-common.component';


@NgModule({
  declarations: [TransactionListComponent, TransactionDetailComponent,
    TransactionTableBodyComponent,
    TransactionCommonComponent],
  imports: [
    CommonModule,
    TransactionManagementRoutingModule,
    FormsModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot()
  ],
  entryComponents: []
})
export class TransactionManagementModule { }
