import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { SeatDataModel } from '../../pricing-management/shared/models/seat-data.model';
import { UserService } from '../../user-management/services';
import { SalesRepModel, UserFilterModel, UserModel } from '../../user-management/shared';
import { CustomerPlanModel } from '../../shared';
import { RegisterService } from '../../user-management/services/register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidateFormService } from '../../services';
import { UserRegisterModel } from '../../user-management/shared/models/user-register.model';
import { NgForm } from '@angular/forms';
import { SalesRepService } from '../../user-management/services/sales-rep.service';
import { HttpHeaders } from '@angular/common/http';
import { NotificationService } from '../../services/notification.service';
import { Observable } from 'rxjs/';

export interface AssignData {
  seat: SeatDataModel | Array<SeatDataModel>;
  plan: number;
}

@Component({
  selector: 'app-assign-customer-modal',
  templateUrl: './assign-customer-modal.component.html',
  styleUrls: ['./assign-customer-modal.component.scss']
})
export class AssignCustomerModalComponent implements OnInit {

  assignData: AssignData;
  closeReason: 'cancel' | 'add';
  customerWanted: string;
  filter: UserFilterModel = {} as UserFilterModel;
  listCustomer: Array<UserModel>;
  showInterface: 'load' | 'search' | 'create' | 'selector';
  defaultUser: UserRegisterModel;
  salesRepByDefault: SalesRepModel;

  // Pagination variables
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  constructor(public bsModalRef: BsModalRef, private userService: UserService,
              private registerService: RegisterService, private route: ActivatedRoute,
              private router: Router, public formValidatorService: ValidateFormService,
              private salesRepService: SalesRepService, private toastr: NotificationService) {
    this.currentPage = 1;
    this.itemsPerPage = 10; // Same number in API Settings.
    this.defaultUser = { is_active: false, type: 'customer' } as UserRegisterModel;
    this.showInterface = 'selector';
  }

  ngOnInit() {
    console.log('Data', this.assignData);
  }

  searchCustomer(): void {
    this.currentPage = 1;
    this.search();
  }

  search(): void {
    this.filter.account_name = this.customerWanted;
    this.userService.searchCustomer(this.filter, this.currentPage).subscribe(
      (response) => {
        this.listCustomer = response;
    });
  }

  onReserveSeatToCustomer(customer: UserModel): void {
    console.log('Assign Seat to this -> ', customer);
    const list = this.parseSeatModelToString([].concat(this.assignData.seat));
    this.registerService.postReserveSeats(list, this.assignData.plan, customer.id)
      .subscribe((response) => {
        this.toastr.showSuccess('', 'Seats reserved successfully');
        this.refreshPage();
        this.onClose('cancel');
      });
  }

  // onAssignCustomer(customer: UserModel): void {
  //   console.log('Assign Seat to this -> ', customer);
  //   this.userService.getCustomerPlan(customer.id).subscribe(
  //     (customerPlanList) => {
  //       const result = this.searchCustomerPlan(customerPlanList, this.assignData.plan);
  //       // Si result es false, significa que el usuario no tiene ningun customer plan con ese plan.
  //       // Le creamos uno de 0 con datos genericos
  //       // En caso contrario, solo tendremos que añadir la silla al customer plan ya existente
  //       if (typeof result === 'boolean') { // Customer sin ese plan
  //         console.log('Nuevo customer plan');
  //         this.createNewCustomerPlan(customer, this.assignData.plan);
  //       } else { // Customer plan con ese customer plan
  //         console.log('Añadir silla al customer plan existences');
  //         this.addSeatCustomerPlan(result);
  //       }
  //   });
  // }

  onClose(reason: 'cancel' | 'add'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  createNewCustomerPlan(customer: UserModel, planId: number): void {
    //   seatList?: Array<{id: string, section: string, row: string, seat: string}>;
    const listOfSeat: any[] = [];
    if (Array.isArray(this.assignData.seat)) {
      for (const item of this.assignData.seat) {
        listOfSeat.push({
          id: item.id,
          section: item.section,
          row: item.seat_row,
          seat: item.seat
        });
      }
    } else {
      listOfSeat.push({
        id: this.assignData.seat.id,
        section: this.assignData.seat.section,
        row: this.assignData.seat.seat_row,
        seat: this.assignData.seat.seat
      });
    }
    const genericCp = {
      user: customer,
      plan: { id: planId },
      addons_allowed: 0,
      appointment_date_to: '2021-01-01T05:00:00Z',
      appointment_date_from_date: '2021-01-01',
      appointment_time_from_date: '00:01',
      appointment_date_to_date: '2022-01-01',
      appointment_time_to_date: '00:01',
      seatList: listOfSeat } as CustomerPlanModel;
    this.registerService.postCreateCustomerPlan(genericCp).subscribe(
      () => {
        this.toastr.showSuccess('', 'Seats assgined successfully');
        this.onClose('cancel');
        this.refreshPage();
      });
  }

  addSeatCustomerPlan(customerPlan: CustomerPlanModel): void {
    const payload = {customer_plan: customerPlan.id, seat: null};
    payload.seat = this.parseSeatModelToString([].concat(this.assignData.seat));
    const promiseList: Array<any> = [];
    for (const seat of payload.seat) {
      promiseList.push(this.registerService.postAddSeat({customer_plan: payload.customer_plan, seat}).toPromise());
    }
    Promise.all(promiseList).then((response) => {
      this.toastr.showSuccess('', 'Seats assgined successfully');
      this.refreshPage();
      this.onClose('cancel');
    });

  }

  searchCustomerPlan(list: Array<CustomerPlanModel>, planWanted: number): CustomerPlanModel | boolean {
    const operation = (element: CustomerPlanModel) => element.plan.id === planWanted;
    const searchResult = list.findIndex(operation);
    if (searchResult !== -1) { return list[searchResult]; }
    return false;
  }

  displayInterface(view: 'load' | 'search' | 'create' | 'selector'): void {
    this.showInterface = view;
    if (view === 'create') {
      this.getGenericSalesRep();
    }
  }

  onSubmitRegisterForm(form: NgForm): void {
    if (form.invalid) { return; }
    this.defaultUser.username = this.defaultUser.email;
    this.defaultUser.account_name = `${this.defaultUser.first_name} ${this.defaultUser.last_name}`;
    this.defaultUser.password = 'mmc2021*';
    this.defaultUser.sales_rep = this.salesRepByDefault.id;
    this.registerService.postCustomerRegistration(this.defaultUser).subscribe((response: UserModel) => {
      // console.log(response);
      // Usuario creado ahora creamos customer plan
      this.toastr.showSuccess('', 'Customer was created');
      this.createNewCustomerPlan(response, this.assignData.plan);
    }, error => {
      this.toastr.showError('', 'Error creating a new customer');
      console.error(error); });
  }

  private getGenericSalesRep(): void {
    if (!this.salesRepByDefault) {
      this.salesRepService.getFilteredSalesRep(1, { username: 'PremiumSales@staplescenter.com' }, new HttpHeaders({ignoreLoadingBar: ''}))
        .subscribe(
        (response) => {
          this.salesRepByDefault = response.results[0];
        });
    }
  }

  private refreshPage(): void {
    // save current route first
    let currentRoute = this.router.url;
    currentRoute = currentRoute.split('?')[0];
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentRoute] , { queryParams: {plan: this.assignData.plan}}); // navigate to same route
    });
  }

  private parseSeatModelToString(listOfSeat: SeatDataModel[]): Array<string> {
    const strList = [];
    for (const seat of listOfSeat) {
      strList.push(seat.id);
    }
    return strList;
  }

}
