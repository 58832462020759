import {Component, Inject, OnInit} from '@angular/core';
import {CommonService} from '../../services';
import {TransactionModel, TransactionFilterModel} from '../shared';
import {saveAs} from 'file-saver';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import { PaginatedResponseModel, PlanModel } from '../../shared/models';
import {NgForm} from '@angular/forms';
import {MessagingService} from '../../shared';
import {TransactionService} from '../services';
import {APP_CONFIG, BackofficeConfigurationModel} from '../../configuration';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html'
})
export class TransactionListComponent implements OnInit {

  transactionList: PaginatedResponseModel<TransactionModel>;
  filter: TransactionFilterModel = {} as TransactionFilterModel;
  bsModalRef: BsModalRef;
  planList: PlanModel[];
  // Pagination variables
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  // tslint:disable-next-line:variable-name
  constructor(@Inject(APP_CONFIG) public app_config: BackofficeConfigurationModel,
              public transactionService: TransactionService, private modalService: BsModalService,
              private commonService: CommonService, private messageService: MessagingService) {
    this.currentPage = 1;
    this.itemsPerPage = 10; // Same number in API Settings.
    this.transactionList = {} as PaginatedResponseModel<TransactionModel>;
  }

  ngOnInit() {

    this.init();
    this.filter.status = '';
    this.filter.plan = '';
  }

  init(): void {
    this.transactionService.getTransactions(this.currentPage).subscribe(data => {
      this.transactionList = data;
      this.totalItems = this.transactionList.count;
    });
    this.transactionService.getPlans().subscribe((response) => {
      this.planList = response;
    });
  }

  onSubmitFilter(): void {
    this.currentPage = 1;
    this.applyFilter();
  }

  applyFilter(): void {
    this.transactionService.getFilterTransactions(this.filter, this.currentPage).subscribe(
      data => {
        this.transactionList = data;
        this.totalItems = this.transactionList.count;
      });
  }

  isRefund(price: number): boolean {
    let refund;
    (price >= 1) ? refund = false : refund = true;
    return refund;
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', {maximumFractionDigits: 2});
  }

  exportOperationsCSV(): void {
    this.transactionService.getTransactionCSV(this.filter).subscribe(
      data => {
        const date = new Date().toLocaleString();
        saveAs(data, `operations_transactions_${date}.csv`);
      }
    );
  }

  exportStaplesEmailConfirmation(): void {
    const tmpFilter = this.filter;
    tmpFilter.status = 'OK';
    this.transactionService.getStaplesMailConfirmationCSV(tmpFilter).subscribe(
      data => {
        const date = new Date().toLocaleString();
        saveAs(data, `email_confirmation_transactions${date}.csv`);
      }
    );
  }

  pageChanged(event) {
    this.currentPage = event.page;
    if (this.commonService.filterisEmpty(this.filter)) {
      this.init();
    } else {
      this.applyFilter();
    }
  }

  restartForm(form: NgForm): void {
    this.currentPage = 1;
    this.filter = {
      customer_account_name: '',
      customer_username: '',
      customer_first_name: '',
      customer_last_name: '',
      status: '',
      season: '',
      plan: '',
      create_date_after: '',
      create_date_before: '',
    };
    form.form.markAsUntouched();
    form.form.markAsPristine();
    this.init();
  }

  private onExportOperations(): void {
    const title = 'Export';
    const message = 'Do you want to export the Operations?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    // this.exportCSV(this.transactionService)
    this.messageService.info(title, message, acceptBtnName, () => {
      this.exportOperationsCSV();
    }, closeBtnName);
  }

  private onExportEmailConfirmations(): void {
    const title = 'Export';
    const message = 'Do you want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    // this.exportCSV(this.transactionService)
    this.messageService.info(title, message, acceptBtnName, () => {
      this.exportStaplesEmailConfirmation();
    }, closeBtnName);
  }


}
