import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/';
import { DjangoSessionAuthenticationService } from '../../auth/django-session-authentication.service';


@Injectable()
/**
 * `HttpInterceptor` which adds an XSRF token to eligible outgoing requests.
 */
@Injectable()
export class HttpXsrfInterceptorMod implements HttpInterceptor {

  constructor(private auth: DjangoSessionAuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* the same of HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
    }),*/
    const headerName = 'X-CSRFTOKEN';
    const token = this.auth.getCsrfToken() as string;
    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next.handle(req);
  }
}
