import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingManagementRoutingModule } from './pricing-management-routing.module';
import { FormsModule } from '@angular/forms';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';
import { PriceTypeManagementComponent } from './price-type-management/price-type-management.component';
import { PriceTypeModalComponent } from './price-type-modal';
import { PriceTypeModalEditComponent } from './price-type-modal-edit/price-type-modal-edit.component';
import { PricingLandingComponent } from './pricing-landing/pricing-landing.component';
import { SideInterfaceComponent } from './side-interface/side-interface.component';
import { SharedModule } from '../shared/shared.module';
import { PriceCommonComponent } from './price-common/price-common.component';


@NgModule({
  declarations: [
    PriceTypeManagementComponent,
    PriceTypeModalComponent,
    PriceTypeModalEditComponent,
    PricingLandingComponent,
    SideInterfaceComponent,
    PriceCommonComponent
  ],
  imports: [
    CommonModule,
    PricingManagementRoutingModule,
    FormsModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule
  ],
  entryComponents: [
    PriceTypeModalEditComponent,
    PriceTypeModalComponent
  ]
})
export class PricingManagementModule { }
