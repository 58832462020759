import { Component, OnInit, Input } from '@angular/core';
import { AdvancedPieModel } from './advanced-pie.model';
import { ChartConfigService } from '../chart-config.service';

@Component({
  selector: 'app-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent implements OnInit {

  @Input() data;
  chartBaseConfig: AdvancedPieModel;
  constructor(private chartConfigService: ChartConfigService) { }

  ngOnInit() {
    this.chartBaseConfig = this.chartConfigService.setAdvancedPieChartConfig(this.data);
  }

  public valueFormat(value: any) {
    console.log('$' + value);
  }

}
