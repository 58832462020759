import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PriceTypeManagementComponent } from './price-type-management/price-type-management.component';
import { AuthGuard } from '../auth';
import { PriceCommonComponent } from './price-common/price-common.component';
import { PricingLandingComponent } from './pricing-landing/pricing-landing.component';


const routes: Routes = [
  {
    path: '',
    component: PriceCommonComponent,
    children: [
      {
        path: 'price-management',
        component: PriceTypeManagementComponent,
      },
      {
        path: 'price-assign',
        component: PricingLandingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingManagementRoutingModule { }
