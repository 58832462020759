import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { FlowControlService } from '../services';

@Injectable()
export class SelectionAreaHandler extends GeneralHandler {
    constructor(
        @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
        protected dvmService: DvmService,
        private flowControl: FlowControlService
    ) {
        super('update_selection_area', dvmService);
    }

    protected handle(obj): void {
        this.dvmService.viewer.unselectAll();
        this.dvmService.viewer.select(obj.selection_area.current);
        this.flowControl.renewHash();
    }
}
