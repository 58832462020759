import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ApplicationRef, Pipe, PipeTransform } from '@angular/core';
import { PricingService } from '../services';
import { BsModalRef, BsModalService, ModalOptions, PageChangedEvent } from 'ngx-bootstrap';
import { PlanModel, BackofficeUser } from '../../shared/models';
import { PriceTypeModel } from '../../shared/models';
import { NewPriceTypePriceModel } from '../shared/models';
import { PriceTypeModalComponent } from '../price-type-modal';
import { PriceTypeModalEditComponent } from '../price-type-modal-edit/price-type-modal-edit.component';
import { NotificationService } from 'src/app/services/notification.service';
import { NgModel } from '@angular/forms';
import { DjangoSessionAuthenticationService } from 'src/app/auth';

@Pipe({ name: 'formatPricePipe'})
export class FormatPricePipe implements PipeTransform {
  transform(val) {
    console.log(typeof val);
    return val.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }
}

@Component({
  selector: 'app-price-type-management',
  styleUrls: ['./price-type-management.component.scss'],
  templateUrl: './price-type-management.component.html'
})
export class PriceTypeManagementComponent implements OnInit, AfterViewChecked {

  planList: Array<PlanModel>;
  resultpricingList: Array<PriceTypeModel>;
  pricingList: Array<PriceTypeModel>;
  bsModalRef: BsModalRef;
  totalItems: number;
  itemsPerPage = 11;
  priceExistHashMap: any;
  currentPage;
  temporal: { [k: number]: string };
  userLogged: BackofficeUser | false;

  constructor(private pricingService: PricingService,
              private modalService: BsModalService,
              private cd: ChangeDetectorRef,
              private notificationService: NotificationService,
              private auth: DjangoSessionAuthenticationService,
  ) {
    this.temporal = {};
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.currentPage = 1;
    this.priceExistHashMap = {};
    this.pricingService.getPriceTable().subscribe(
      response => {
        this.planList = response.plans;
        this.resultpricingList = response.price_types;
        this.totalItems = this.pricingList.length;
        // this.resultpricingList = this.pricingList.slice(0, this.itemsPerPage);
        this.cd.detectChanges();
      }, error => {
        console.log(error);
      }
    );
    this.auth.getUserLogged$().subscribe(
      user => {
        this.userLogged = user;
      }
    );
  }

  // assignPrice(newPTP: NewPriceTypePriceModel): void {

  //   if (newPTP.price <= 0) {
  //     newPTP.price = 0;
  //   }
  //   const modalConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered',
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //     initialState: {
  //       priceTypeprice: newPTP
  //     }
  //   };
  //   // @ts-ignore
  //   this.bsModalRef = this.modalService.show(PriceTypeModalComponent, modalConfig);
  //   const subscription = this.modalService.onHide.subscribe((reason: string) => {
  //     subscription.unsubscribe();
  //     if (this.bsModalRef.content.closeReason === 'remove') {
  //       this.notificationService.showSuccess('Price type deleted');
  //       const filter = (obj) => obj.id === this.bsModalRef.content.priceTypeprice.price_type;
  //       const index = this.pricingList.findIndex(filter);
  //       delete this.priceExistHashMap[this.bsModalRef.content.priceTypeprice.price_type_name];
  //       delete this.pricingList[index].prices[this.bsModalRef.content.priceTypeprice.plan];
  //     } else if (this.bsModalRef.content.closeReason === 'update') {
  //       this.notificationService.showSuccess('Price type modified');
  //       const filter = (obj) => obj.id === this.bsModalRef.content.priceTypeprice.price_type;
  //       const priceObj = this.pricingList[this.pricingList.findIndex(filter)];
  //       priceObj.prices[this.bsModalRef.content.priceTypeprice.plan] = this.bsModalRef.content.resultPriceTypePrice;
  //     } else {
  //     }
  //   });
  // }

  newPriceType(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        priceType: undefined
      }
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(PriceTypeModalEditComponent, modalConfig);
    const subscription = this.modalService.onHide.subscribe((reason: string) => {
      subscription.unsubscribe();
      if (this.bsModalRef.content.closeReason === 'create') {
        this.notificationService.showSuccess('Price type created');
        // tslint:disable-next-line:variable-name
        // const _pricetype = this.bsModalRef.content.priceType;
        // _pricetype.prices = {};
        // this.pricingList.push(_pricetype);
        this.init();
      }

    },
      error => {
        console.log(error);
        this.notificationService.showError('Could not create price type');
      });
  }

  // editPriceType(pricetype: PriceTypeModel): void {
  //   const modalConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered',
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //     initialState: {
  //       priceType: pricetype
  //     }
  //   };

  //   this.bsModalRef = this.modalService.show(PriceTypeModalEditComponent, modalConfig);
  //   const subscription = this.modalService.onHide.subscribe((reason: string) => {
  //     subscription.unsubscribe();
  //     if (this.bsModalRef.content.closeReason === 'update') {
  //       this.notificationService.showSuccess('Price type modified');
  //     }
  //     // this.pricingList.push(this.bsModalRef.content.responsePriceType);
  //   },
  //     error => {
  //       this.notificationService.showError('Could not modify price type');
  //     });
  // }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.currentPage = event.page;
    this.resultpricingList = this.pricingList.slice(startItem, endItem);
  }

  checkPriceForPlanExist(priceMap, planId, pricingName): boolean {
    if (priceMap[planId]) {
      this.priceExistHashMap[pricingName] = true;
      return true;
    }
    return false;
  }

  onDeletePriceType(priceType: number): void {
    this.pricingService.deletePriceType(priceType).subscribe(
      response => {
        const e = this.pricingList.findIndex((u) => {
          return u.id === priceType;
        });
        delete this.pricingList[e];
        this.notificationService.showSuccess('Price type successfully deleted');
        this.init();
      },
      error => {
        this.notificationService.showError(`Couldn't delete price type`);
        console.error(error);
      }
    );
  }

  // When user put the focus in the input
  onFocusPrice(target: HTMLInputElement, priceid: number, numberLocalizedElement?: HTMLElement): void {
    target.hidden = false;
    target.focus();
    target.readOnly = false;
    target.classList.remove('input-transparent');
    target.classList.add('input');
    if (numberLocalizedElement) {
      numberLocalizedElement.hidden = true;
    }
    this.temporal[priceid] = target.value; // Save actual value, request can fail
  }

  // When user leaves the input
  // tslint:disable-next-line:max-line-length
  onBlurPrice(target: HTMLInputElement, priceid: number, planId: number, action: 'update' | 'new', numberLocalizedElement?: HTMLElement): void {
    target.readOnly = true;
    target.classList.add('input-transparent');
    target.classList.remove('input');
    if (numberLocalizedElement) {
      target.hidden = true;
      numberLocalizedElement.hidden = false;
    }
    if (target.classList.contains('ng-pristine')) {
      return;
    }
    switch (action) {
      case 'update':
        this.pricingService.patchPriceTypePrice(priceid, target.value).subscribe(
          (response) => {
            this.notificationService.showSuccess('New Price successfully saved');
            this.init();
          }, error => {
            target.value = this.temporal[priceid];
            this.notificationService.showError(`New Price couldn't be saved`);
          }
        );
        break;
      case 'new':
        const newPriceTypePrice: NewPriceTypePriceModel = {
          price_type: priceid,
          plan: planId,
          price: parseInt(target.value, 10),
        };

        // @ts-ignore
        if ('id' in this.userLogged) {
          newPriceTypePrice.updated_by = this.userLogged.id;
          newPriceTypePrice.created_by = this.userLogged.id;
        }

        this.pricingService.postNewPriceTypePrice(newPriceTypePrice).subscribe(
          (response) => {
            this.notificationService.showSuccess('New price successfully saved');
            this.init();
          }, error => {
            target.value = this.temporal[priceid];
            this.notificationService.showError(`New Price couldn't be saved`);
          }
        );
        break;

      default:
        break;
    }

  }

  onFocusPriceTypeName(target: HTMLInputElement): void {
    target.readOnly = false;
    target.classList.remove('input-transparent');
    target.classList.add('input');
  }

  onBlurPriceTypeName(target: HTMLInputElement, pricetypeid: number): void {
    target.readOnly = true;
    target.classList.add('input-transparent');
    target.classList.remove('input');
    if (target.classList.contains('ng-pristine')) { return; }
    this.pricingService.patchPriceType(pricetypeid, target.value).subscribe(
      (response) => {
        this.notificationService.showSuccess('Name successfully saved');
        this.init();
      }, error => {
        this.notificationService.showSuccess(`Name couldn't be saved`);
      }
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  // onRemovePriceType(): void {
  //   console.log('delete');
  // }

  onDeletePriceTypePrice(priceType): void {
    this.pricingService.deletePriceTypePrice(priceType.id).subscribe(
      response => {
        const e = this.pricingList.findIndex((u) => {
          return u.id === priceType.price_type;
        });
        delete this.pricingList[e].prices[priceType.plan];
        this.notificationService.showSuccess('Price successfully deleted');
        this.init();
      }, error => {
        this.notificationService.showError(`Price couldn't be deleted`);
      }
    );
  }

  checkLength(txt: string): number {
    return txt.length;
  }
}
